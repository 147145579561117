<div *ngIf="title !== ''" class="app-dashboard-title">
  <h4 class="add-title">
    <img
      *ngIf="hasBackButton"
      class="back-arrow"
      src="/assets/images/purpl/back-arrow.png"
      alt="back-arrow"
      (click)="backArrowClicked()"
    />
    {{ title }}
  </h4>
  <div
    class="add-title-role text-capitalize"
    *ngIf="position && (position !== null || position !== '')"
  >
    <div *ngIf="title !== ''">{{ position?.replace("_", " ") }} Panel</div>
  </div>
</div>

<div *ngIf="title === ''" class="user-profile-view">
  <div>
    <img
      class="user-img rounded-circle"
      [src]="
        details?.image ? details?.image : '/assets/images/dashboard/man.png'
      "
      alt="app-user-profile-img"
    />
  </div>
  <div class="username-view">
    <label class="user-name"
      >{{ details?.first_name }} {{ details?.last_name }}</label
    ><br />
    <div class="other-details-view">
      <ng-container
        *ngIf="
          allFeatures?.is_enabled === true &&
          cashOutFeature?.is_enabled === true
        "
      >
        <!-- <label class="status" [ngClass]="getUserEKYCStatusClass()">
          <div class="mr-1">
            <img src="./assets/images/svg/check.svg" />
          </div>
          {{ getUserEKYCStatus() }}</label
        > -->

        <label
          class="status fully-verified"
          *ngIf="
            details?.compliance?.verification_status === 'FULLY_VERIFIED' ||
            details?.compliance?.verification_status === 'VERIFIED' ||
            details?.compliance?.verification_status === 'VERIFIED_2'
          "
        >
          <div class="mr-1">
            <img src="./assets/images/svg/check.svg" />
            {{ details?.compliance?.verification_status }}
          </div>
        </label>
        <label
          class="status soft-deleted"
          *ngIf="
            details?.compliance?.verification_status === 'SOFT_DELETED' ||
            details?.compliance?.verification_status === 'PENDING'
          "
        >
          {{ details?.compliance?.verification_status }}</label
        >
        <label
          class="status unverified"
          *ngIf="details?.compliance?.verification_status === 'UNVERIFIED'"
          >{{ details?.compliance?.verification_status }}</label
        >
        <label
          class="status jumio"
          *ngIf="
            details?.compliance?.verification_status === 'BOUNCE_JUMIO' ||
            details?.compliance?.verification_status === 'FAILED_JUMIO'
          "
          >{{ details?.compliance?.verification_status }}</label
        >
      </ng-container>

      <label
        class="status suspended"
        *ngIf="cashOutFeature?.is_enabled === false"
      >
        <div class="mr-1">
          <img src="./assets/images/svg/warning-circle.svg" />
        </div>
        {{ cashOutFeature?.is_enabled ? "" : "SUSPENDED" }}
      </label>

      <label class="status suspended" *ngIf="allFeatures?.is_enabled === false">
        <div class="mr-1">
          <img src="./assets/images/svg/warning-circle.svg" />
        </div>
        {{ allFeatures?.is_enabled ? "" : "SUSPENDED" }}
      </label>

      <label
        class="status blocked"
        *ngIf="details?.account_non_locked === false"
      >
        {{ details?.account_non_locked ? "" : "Blocked" }}
      </label>

      <label class="contacts">{{ details?.email }} </label>
      <label class="contacts"
        >+{{ details?.country_code }}{{ details?.phone_number }}</label
      >
    </div>
  </div>
</div>
