import { Component, Input, OnInit } from '@angular/core';
import { DASHBOARD_VERIFIED_USERS } from 'src/app/pages/dashboard/pages/user-comparison-dashboard/contants';
import {
  COMPLIANCE_VERIFICATION_STATUS,
  USER_STATUS_ID,
  VERIFICATION,
} from '../../constants/constant-list';
import { toUpper } from 'lodash';
import { AppUsersService } from '../../services/app-users.service';
import { VerificationType } from '../../interfaces/Compliance';
import { IUser } from '../../interfaces/User';

@Component({
  selector: 'purpl-verification-badge',
  templateUrl: './verification-badge.component.html',
  styleUrls: ['./verification-badge.component.scss'],
})
export class VerificationBadgeComponent implements OnInit {
  @Input() iconOnly: boolean = true;
  @Input() compliance: any;
  @Input() status_id: number | undefined;
  @Input() userCode: string | undefined;
  @Input() did_jumio_and_failed?: boolean;
  @Input() bounced_jumio?: boolean;

  verificationStatus: VerificationType | null = null;
  details?: IUser;

  constructor(private appUserService: AppUsersService) {}

  ngOnInit(): void {
    if (this.userCode) {
      this.loadUserVerifiedData();
    }
  }

  userVerificationStatus(statusId: any, compliance: any): string {
    switch (statusId) {
      case USER_STATUS_ID.UN_VERIFIED:
        return '/assets/images/svg/star-cross.svg';
      case USER_STATUS_ID.VERIFIED:
        if (compliance === null) {
          return '/assets/images/svg/star-warning.svg';
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.PENDING
        ) {
          return '/assets/images/svg/star-warning.svg';
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.REJECTED
        ) {
          return '/assets/images/svg/star-warning.svg';
        } else {
          return '/assets/images/svg/star-tick.svg';
        }
      case USER_STATUS_ID.PENDING:
        return '/assets/images/svg/star-warning.svg';
      case USER_STATUS_ID.SOFT_DELETED:
        return '/assets/images/svg/icon_soft_deleted.svg';
      case USER_STATUS_ID.BLOCKED:
        return '/assets/images/svg/icon_user_block.svg';
      default:
        return '/assets/images/svg/star-cross.svg';
    }
  }

  verificationStatusTooltip(statusId: any, compliance: any): string {
    switch (statusId) {
      case USER_STATUS_ID.UN_VERIFIED:
        if (this.did_jumio_and_failed) {
          return DASHBOARD_VERIFIED_USERS.FAILED;
        }

        if (this.bounced_jumio) {
          return DASHBOARD_VERIFIED_USERS.BOUNCED;
        }

        return DASHBOARD_VERIFIED_USERS.UNVERIFIED;
      case USER_STATUS_ID.VERIFIED:
        if (compliance === null) {
          return DASHBOARD_VERIFIED_USERS.FULLY;
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.PENDING
        ) {
          return DASHBOARD_VERIFIED_USERS.JUMIO;
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.REJECTED
        ) {
          return DASHBOARD_VERIFIED_USERS.PENDING;
        } else {
          return 'Verified';
        }
      case USER_STATUS_ID.PENDING:
        return DASHBOARD_VERIFIED_USERS.PENDING;
      case USER_STATUS_ID.SOFT_DELETED:
        return DASHBOARD_VERIFIED_USERS.SOFT_DELETED;
      case USER_STATUS_ID.BLOCKED:
        return DASHBOARD_VERIFIED_USERS.BLOCKED;
      default:
        return '';
    }
  }

  userVerificationStatusClass(statusId: any, compliance: any): string {
    switch (statusId) {
      case USER_STATUS_ID.UN_VERIFIED:
        if (this.did_jumio_and_failed) {
          return 'failed';
        }
        return 'un-verified';
      case USER_STATUS_ID.VERIFIED:
        if (compliance === null) {
          return 'verified';
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.PENDING
        ) {
          return 'pending';
        } else if (
          compliance &&
          (compliance?.verification_status).toUpperCase() ===
            COMPLIANCE_VERIFICATION_STATUS.REJECTED
        ) {
          return 'rejected';
        } else {
          return 'verified';
        }
      case USER_STATUS_ID.PENDING:
        return 'pending';
      case USER_STATUS_ID.SOFT_DELETED:
        return 'deleted';
      case USER_STATUS_ID.BLOCKED:
        return 'blocked';
      default:
        return '';
    }
  }

  getUserEKYCStatus(): string {
    return toUpper(this.verificationStatus!);
  }

  getUserEKYCStatusClass(): string {
    if (this.verificationStatus === VERIFICATION.verified) {
      return 'fully-verified';
    } else if (
      this.verificationStatus === VERIFICATION.pending ||
      this.verificationStatus === VERIFICATION.soft_deleted
    ) {
      return 'soft-deleted';
    } else if (this.verificationStatus === VERIFICATION.failed) {
      return 'jumio';
    } else if (this.verificationStatus === VERIFICATION.in_progress) {
      return 'unverified';
    }
    return '';
  }

  async loadUserVerifiedData() {
    this.appUserService.getUsersKYCVerificationInfo(this.userCode!).subscribe({
      next: (response) => {
        const verifiedStatus = response?.verificationStatus;
        this.verificationStatus = verifiedStatus;
      },
    });
  }
}
