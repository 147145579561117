<div class="custom-date-picker-container">
  <div class="row single-step-container">
    <mat-selection-list #date_range_picker class="date-range-options-menu" [multiple]="false"
      (selectionChange)="onFilterChange($event)">
      <mat-list-option [selected]="filterValue === item.value" [value]="item.value"
        *ngFor="let item of DATE_RANGE_OPTIONS">
        <div class="d-flex align-items-center justify-content-between w-100">
          <span>{{item.label}}</span>
          <mat-icon matSuffix *ngIf="filterValue === item.value">done</mat-icon>
        </div>
      </mat-list-option>
    </mat-selection-list>
    <mat-divider></mat-divider>
    <div>
      <mat-card-content>
        <div class=" selected-dates-container align-items-center align-content-center flex-column">
          <div class="selected-date-range-label">
            {{filterLabel}}
          </div>
          <div class="selected-dates">
            <div class="date-input">
              {{primaryStartDate}}</div>
            <span class="dates-divider">–</span>
            <div class="date-input">
              {{primaryEndDate}}</div>
          </div>
        </div>
        <app-purple-calendar [selected]="selectedRangeValue" (selectedChange)="selectedRangeChange($event)">
        </app-purple-calendar>
        <!-- <mat-calendar #calendar1 [selected]="selectedRangeValue" (selectedChange)="selectedRangeChange($event)">
        </mat-calendar>
        <mat-calendar #calendar2 [selected]="selectedRangeValue" (selectedChange)="selectedRangeChange($event)">
        </mat-calendar> -->
      </mat-card-content>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-card-actions align="end" class="p-3">
    <button mat-button (click)="closeDatePicker()">Cancel</button>
    <button mat-button color="primary" (click)="applyDatePicker()">Apply</button>
  </mat-card-actions>
</div>


<mat-menu #this_week="matMenu">
  <button mat-menu-item>Sun - Today</button>
  <button mat-menu-item>Mon - Today</button>
  <button mat-menu-item>Sat - Fri</button>
</mat-menu>

<mat-menu #last_week="matMenu">
  <button mat-menu-item>Sun - Sat</button>
  <button mat-menu-item>Mon - Sun</button>
  <button mat-menu-item>Sat - Fri</button>
</mat-menu>
