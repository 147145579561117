import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DASHBOARD_TABS } from '../../contants';

export interface IDashboardTabs {
  label: string;
  route: string;
  key: string;
}

@Component({
  selector: 'purpl-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.scss'],
})
export class DashboardTabsComponent implements OnInit {
  tabs: IDashboardTabs[] = DASHBOARD_TABS;
  @Input() key?: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigate(route: string) {
    this.router.navigateByUrl(route);
  }
}
