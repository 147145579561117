import { Location } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { IUser } from '../../interfaces/User';
import { FormBaseComponent } from '../base/form-base.component';
import { Features } from '../../interfaces/Features';
import _, { toUpper } from 'lodash';
import { Store } from '@ngrx/store';
import {
  selectAppUser,
  selectAppUserFeatures,
} from '../../stores/slices/AppUsers';
import { VerificationType } from '../../interfaces/Compliance';
import { selectUserEKYCStatus } from '../../stores/slices/EKYC';
import { VERIFICATION } from '../../constants/constant-list';

@Component({
  selector: 'purpl-dashboard-title',
  templateUrl: './app-dashboard-title.component.html',
  styleUrls: ['./app-dashboard-title.component.scss'],
})
export class AppDashboardTitleComponent
  extends FormBaseComponent
  implements OnInit
{
  @Input() position?: string;
  @Input() title?: string;
  @Input() hasBackButton?: boolean;
  @Input() details?: Partial<IUser>;

  // @Input() details: {
  //   id: string;
  //   first_name: string;
  //   last_name: string;
  //   country_code: string;
  //   phone_number: string;
  //   verification_status: string;
  //   email: string;
  //   compliance: any[];
  //   features: any[];
  //   image: string;
  //   account_non_locked: boolean;
  // } = {
  //   id: '',
  //   first_name: '',
  //   last_name: '',
  //   country_code: '',
  //   phone_number: '',
  //   verification_status: '',
  //   email: '',
  //   compliance: [],
  //   features: [],
  //   image: '',
  //   account_non_locked: false,
  // };

  features: Features[] = [];
  cashOutFeature: Features | null = null;
  allFeatures: Features | null = null;
  verificationStatus: VerificationType | null = null;

  constructor(
    private _location: Location,
    injector: Injector,
    private store: Store
  ) {
    super(injector);

    this.store.select(selectUserEKYCStatus).subscribe((response) => {
      this.verificationStatus = response;
    });

    this.store.select(selectAppUser).subscribe((response) => {
      if (response?.compliance) {
        this.details = response;

        this.generateFeatures(this.details?.features!);
      }
    });

    this.store.select(selectAppUserFeatures).subscribe((response) => {
      if (response) {
        this.features = response;
        this.generateFeatures(this.features);
      }
    });
  }

  ngOnInit(): void {
    this.title = this.title === undefined ? 'Partners Transaction' : this.title;
  }

  backArrowClicked() {
    this._location.back();
  }

  private generateFeatures = (_features: Features[]) => {
    this.cashOutFeature = _.find(this.details?.features, {
      feature: 'cash-out',
    })!;
    this.allFeatures = _.find(this.details?.features, {
      feature: 'all',
    })!;
  };

  getUserEKYCStatus(): string {
    return toUpper(this.verificationStatus!);
  }

  getUserEKYCStatusClass(): string {
    if (this.verificationStatus === VERIFICATION.verified) {
      return 'fully-verified';
    } else if (
      this.verificationStatus === VERIFICATION.pending ||
      this.verificationStatus === VERIFICATION.soft_deleted
    ) {
      return 'soft-deleted';
    } else if (this.verificationStatus === VERIFICATION.failed) {
      return 'jumio';
    } else if (this.verificationStatus === VERIFICATION.in_progress) {
      return 'unverified';
    }
    return '';
  }
}

// private generateFeatures = (_features: Features[]) => {

// this.store.select(selectAppUser).subscribe((response) => {
//   if (response?.compliance) {
//     this.details = response;
//     this.generateFeatures(this.details?.features!);
//   }
// });

// this.store.select(selectAppUserFeatures).subscribe((response) => {
//   if (response) {
//     this.features = response;
//     this.generateFeatures(this.features);
//   }
// });
