<ng-container *ngIf="iconOnly; else textBadge">
  <img
    *ngIf="iconOnly"
    class="verification-badge"
    [src]="userVerificationStatus(status_id, compliance)"
    alt="verification"
    [ngbTooltip]="verificationStatusTooltip(status_id, compliance)"
  />
</ng-container>

<ng-template #textBadge>
  <div
    class="verification-text-badge"
    [class]="userVerificationStatusClass(status_id, compliance)"
  >
    {{ verificationStatusTooltip(status_id, compliance) }}
    <!-- {{ getUserEKYCStatus() }} -->
  </div>
</ng-template>
