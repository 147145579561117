import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import moment from 'moment';
import {
  DateRange,
  DateType,
} from '../purple-calendar/purple-calendar.component';
import { DATE_RANGE_OPTIONS } from '../purple-date-picker.constant';

@Component({
  selector: 'app-purple-date-picker-dialog',
  templateUrl: './purple-date-picker-dialog.component.html',
  styleUrls: ['./purple-date-picker-dialog.component.scss'],
})
export class PurpleDatePickerDialogComponent implements OnInit {
  activeDate: string = 'PRIMARY_START';
  filterValue: string = 'today';
  filterLabel?: string;

  primaryStartDate?: Date | string;
  primaryEndDate?: Date | string;
  staticEndDate?: Date | string;
  DATE_RANGE_OPTIONS: {
    label: string;
    value: string;
  }[] = DATE_RANGE_OPTIONS;

  selectedRangeValue?: DateRange | any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PurpleDatePickerDialogComponent>
  ) {
    this.filterValue = data?.filter;
  }

  ngOnInit(): void {
    const options: any = DATE_RANGE_OPTIONS.find(
      (item) => item.value === this.filterValue
    );
    this.filterLabel = options?.label;

    if (options.startDate) {
      this.primaryStartDate = moment(options.startDate).format('MMM DD YYYY');
    }
    if (options.endDate) {
      this.primaryEndDate = moment(options.endDate).format('MMM DD YYYY');
    }

    this.selectedRangeValue = {
      start: moment(options.startDate).toDate(),
      end: moment(options.endDate).toDate(),
    };
  }

  selectedRangeChange(ev: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = ev;
    } else {
      const start = this.selectedRangeValue.start;
      const end = ev.end;

      if (end < start) {
        this.selectedRangeValue = {
          start: end,
          end: start,
        };
      } else {
        this.selectedRangeValue = ev;
      }
    }
    this.filterValue = 'custom';
  }

  getStartDate() {}

  getEndDate() {}

  onFilterChange(ev: MatSelectionListChange) {
    this.filterValue = ev.options[0]?.value;

    if (this.filterValue !== 'custom') {
      const options: any = DATE_RANGE_OPTIONS.find(
        (item) => item.value === this.filterValue
      );
      this.filterLabel = options?.label;
      if (options.startDate) {
        this.primaryStartDate = moment(options.startDate).format('MMM DD YYYY');
      }
      if (options.endDate) {
        this.primaryEndDate = moment(options.endDate).format('MMM DD YYYY');
      }

      this.selectedRangeValue = {
        start: moment(options.startDate).toDate(),
        end: moment(options.endDate).toDate(),
      };
    }
  }

  closeDatePicker() {
    this.dialogRef.close();
  }

  applyDatePicker() {
    const filterObj: any = DATE_RANGE_OPTIONS.find(
      (item) => item.value === this.filterValue
    );

    const result = {
      filter: this.filterValue,
      filterObj: {
        label: filterObj.label,
        value: filterObj.value,
      },
      filters: filterObj.filters,
      startDate: moment(this.selectedRangeValue?.start),
      endDate: moment(this.selectedRangeValue?.end),
      startDateJS: this.selectedRangeValue.start,
      endDateJS: this.selectedRangeValue.end,
      startDateISO: moment(this.selectedRangeValue.start).toISOString(),
      endDateISO: moment(this.selectedRangeValue.end).toISOString(),
    };
    this.dialogRef.close(result);
  }
}
