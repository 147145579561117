import moment from 'moment';

const thisMonthRange = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  let yearFrom, monthFrom, yearTo, monthTo;
  yearFrom = y;
  monthFrom = m;
  yearTo = y;
  monthTo = m + 1;

  if (m === 11) {
    yearTo = y + 1;
    monthTo = 0;
  }

  return {
    startDate: new Date(yearFrom, monthFrom, 1),
    endDate: new Date(yearTo, monthTo, 0),
  };
};

const lastMonthRange = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  let yearFrom, monthFrom, yearTo, monthTo;
  yearFrom = y;
  monthFrom = m - 1;
  yearTo = y;
  monthTo = m;

  if (m === 0) {
    yearFrom = y - 1;
    monthFrom = 11;
  }

  return {
    startDate: new Date(yearFrom, monthFrom, 1),
    endDate: new Date(yearTo, monthTo, 0),
  };
};

const lastTwelveMonthsRange = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  let yearFrom, monthFrom, yearTo, monthTo;
  yearFrom = y;
  monthFrom = m - 12; // current month - number desired
  yearTo = y;
  monthTo = m;

  if (monthFrom < 0) {
    yearFrom = y - 1;
    monthFrom = 11 + 1 + monthFrom;
  }

  return {
    startDate: new Date(yearFrom, monthFrom, 1),
    endDate: new Date(yearTo, monthTo, 0),
  };
};

const lastCalendarYear = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  let yearFrom, monthFrom, yearTo, monthTo;
  yearFrom = y - 1;
  monthFrom = 0; // current month - number desired
  yearTo = y;
  monthTo = m;

  if (monthFrom < 0) {
    yearFrom = y - 1;
    monthFrom = 11 + 1 + monthFrom;
  }

  return {
    startDate: new Date(yearFrom, monthFrom, 1),
    endDate: new Date(yearTo, monthTo, 0),
  };
};

const thisCalendarYear = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  let yearFrom, monthFrom, yearTo, monthTo;

  yearFrom = y;
  monthFrom = 0; // January
  yearTo = y;
  monthTo = m;

  return {
    startDate: new Date(yearFrom, monthFrom, 1),
    endDate: new Date(),
  };
};

export const DATE_RANGE_OPTIONS = [
  {
    label: 'Today',
    value: 'today',
    startDate: new Date(),
    endDate: new Date(),
    filters: ['D'],
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    filters: ['D'],
  },
  {
    label: 'This week',
    value: 'this_week',
    startDate: moment().startOf('week').toDate(),
    endDate: new Date(),
    filters: ['D'],
  },
  {
    label: 'Last week',
    value: 'last_week',
    startDate: moment().subtract(1, 'week').startOf('week').toDate(),
    endDate: moment().subtract(1, 'week').endOf('week').toDate(),
    filters: ['D'],
  },
  // {
  //   label: 'Last 7 days',
  //   value: 'last_7_days',
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
  //   endDate: new Date(),
  //   filters: ["D"]
  // },
  // {
  //   label: 'Last 28 days',
  //   value: 'last_28_days',
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 27)),
  //   endDate: new Date(),
  // },
  // {
  //   label: 'Last 30 days',
  //   value: 'last_30_days',
  //   startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
  //   endDate: new Date(),
  //   filters: ["D","W"]
  // },
  {
    label: 'Last 90 days',
    value: 'last_90_days',
    startDate: new Date(new Date().setDate(new Date().getDate() - 89)),
    endDate: new Date(),
    filters: ['D', 'W'],
  },
  {
    label: 'Last month',
    value: 'last_month',
    filters: ['D', 'W'],
    ...lastMonthRange(),
  },
  // {
  //   label: 'Last 12 months',
  //   value: 'last_12_months',
  //   filters: ["D","W","M"],
  //   ...lastTwelveMonthsRange(),
  // },
  {
    label: 'Last calendar year',
    value: 'last_calendar_year',
    filters: ['D', 'W', 'M'],
    ...lastCalendarYear(),
  },
  {
    label: 'This month',
    value: 'this_month',
    filters: ['D', 'W'],
    ...thisMonthRange(),
  },
  {
    label: 'This year',
    value: 'this_year',
    filters: ['D', 'W', 'M'],
    ...thisCalendarYear(),
  },
  {
    label: 'Custom',
    value: 'custom',
    startDate: null,
    endDate: null,
    filters: ['D', 'W', 'M', 'Y'],
  },
];
