import { Component } from '@angular/core';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { IFloatingFilterParams, ISimpleFilter } from 'ag-grid-community';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

export interface CustomParams {
  suppressFilterButton: boolean;
  filter: string;
  onSearchEvent: (event: any) => void;
}

@Component({
  selector: 'app-transaction-status-search',
  templateUrl: './transaction-status-search.component.html',
  styleUrls: ['./transaction-status-search.component.scss'],
})
export class TransactionStatusSearchComponent
  implements IFloatingFilterAngularComp
{
  filterSearchText = new Subject<any>();
  filterText: string | null = null;
  eFilterText: any;
  params!: IFloatingFilterParams<ISimpleFilter> & CustomParams;

  constructor() {
    this.filterText = null;
  }

  agInit(params: IFloatingFilterParams<ISimpleFilter> & CustomParams) {
    this.filterText = null;
    this.params = params;

    this.filterSearchText
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe({
        next: (event: any) => {
          this.params?.onSearchEvent({
            value: event?.target?.value,
            filter: params?.filter,
          });
        },
      });
  }

  onParentModelChanged(parentModel: any) {
    // When the filter is empty we will receive a null value here
    if (!parentModel) {
      this.filterText = null;
    } else {
      this.filterText = parentModel.filter;
    }
  }

  public onFilterCompletedAML(event: any) {
    this.params?.onSearchEvent({
      value: event?.checked,
      filter: 'completedAmlCheck',
    });
  }
}
