import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { NavService } from './services/nav.service';
import { WINDOW_PROVIDERS } from './services/windows.service';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';

import { JumioUserDetailsComponent } from './components/jumio-user-details/jumio-user-details.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { CashoutTransactionModalComponent } from './components/cashout-transaction-modal/cashout-transaction-modal.component';
import { ReceivedTransactionsModalComponent } from './components/received-transactions-modal/received-transactions-modal.component';
import { PipesModule } from './pipes/pipes.module';
import { SkeletonItemModule } from './components/skeleton-item/skeleton-item.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ZoomImagesComponent } from './components/zoom-images/zoom-images.component';

@NgModule({
  declarations: [
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    JumioUserDetailsComponent,
    SearchBarComponent,
    CashoutTransactionModalComponent,
    ReceivedTransactionsModalComponent,
    ZoomImagesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    OverlayModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    PipesModule,
    SkeletonItemModule,
    MatDialogModule,
    NgxImageZoomModule,
  ],
  providers: [NavService, WINDOW_PROVIDERS, DatePipe],
  exports: [
    FeatherIconsComponent,
    JumioUserDetailsComponent,
    SearchBarComponent,
    CashoutTransactionModalComponent,
    ReceivedTransactionsModalComponent,
    SidebarComponent,
    RightSidebarComponent,
    HeaderComponent,
  ],
})
export class SharedModule {}
