<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text" mat-button (click)="openDatePicker($event)">
      <div class="input-group-text-filter">
        {{filterLabel}}
      </div>
    </div>
    <div class="input-group-text">
      <mat-icon>event</mat-icon>
    </div>
  </div>
  <input type="text" readonly class="form-control" placeholder="Select Date - Select Date" [value]="formattedDate"
    (click)="openDatePicker($event)">
</div>
