import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiResponseBodyInterface } from '../../interfaces/api-response.interface';
import { VerificationType } from '../../interfaces/Compliance';

export interface EKYCState {
  //   ekyc: ApiResponseBodyInterface | null;
  formValues: Record<string, any> | null;
  profileStatus: VerificationType | null;
}

const initialState: EKYCState = {
  formValues: null,
  profileStatus: null,
};

const { actions, reducer, name } = createSlice({
  name: 'ekyc',
  initialState,
  reducers: {
    setFormValues: (
      state,
      { payload }: PayloadAction<Record<string, any> | null>
    ) => {
      state.formValues = {
        ...state.formValues,
        ...payload,
      };
    },
    setProfileStatus: (
      state,
      { payload }: PayloadAction<VerificationType | null>
    ) => {
      state.profileStatus = payload;
    },
    clearFormValues: () => {
      return initialState;
    },
  },
});
const selectState = createFeatureSelector<ReturnType<typeof reducer>>(name);

export const selectFormValues = createSelector(
  selectState,
  (state) => state.formValues
);

export const selectUserEKYCStatus = createSelector(
  selectState,
  (state) => state.profileStatus
);

export { name };
export const { setFormValues, clearFormValues, setProfileStatus } = actions;
export default reducer;
