import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { DateRange } from '../purple-calendar/purple-calendar.component';
import { PurpleDatePickerDialogComponent } from '../purple-date-picker-dialog/purple-date-picker-dialog.component';
import { DATE_RANGE_OPTIONS } from '../purple-date-picker.constant';

@Component({
  selector: 'app-purple-date-picker',
  templateUrl: './purple-date-picker.component.html',
  styleUrls: ['./purple-date-picker.component.scss'],
})
export class PurpleDatePickerComponent implements OnInit {
  dialogRef?: MatDialogRef<any>;

  @Input() filter?: string;
  @Input() selectedRangeValue?: DateRange;

  @Output() selectedRangeValueChange = new EventEmitter<any>();

  filterValue?: string;
  filterLabel?: string;
  dateStart?: string;
  dateEnd?: string;
  formattedDate?: string;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    const options: any = DATE_RANGE_OPTIONS.find(
      (item) => item.value === this.filter
    );
    this.filterLabel = options?.label;

    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      if (!isEmpty(String(options.startDate))) {
        this.dateStart = moment(options.startDate).format('MMM D, YYYY');
      }
      if (!isEmpty(String(options.endDate))) {
        this.dateEnd = moment(options.endDate).format('MMM D, YYYY');
      }
      this.formattedDate = `${this.dateStart} - ${this.dateEnd}`;
    } else {
      this.dateStart = moment(this.selectedRangeValue.start).format(
        'MMM D, YYYY'
      );
      this.dateEnd = moment(this.selectedRangeValue.end).format('MMM D, YYYY');
      this.formattedDate = `${this.dateStart} - ${this.dateEnd}`;
    }
  }

  openDatePicker(ev: any) {
    const targetAttr = ev?.target?.getBoundingClientRect();
    const top = Number(targetAttr.y + targetAttr.height + 10);
    const left = Number(targetAttr.x - targetAttr.width - 250);
    this.dialogRef = this.dialog.open(PurpleDatePickerDialogComponent, {
      id: 'purple-date-picker',
      position: {
        top: top + 'px',
        left: left + 'px',
      },
      data: {
        filter: this.filterValue || this.filter,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter = result?.filter;
        this.filterValue = result?.filter;
        this.filterLabel = result?.filterObj?.label;

        this.dateStart = moment(result?.startDate).format('MMM D, YYYY');
        this.dateEnd = moment(result?.endDate).format('MMM D, YYYY');
        this.formattedDate = `${this.dateStart} - ${this.dateEnd}`;

        this.selectedRangeValueChange.emit({
          filter: result.filter,
          filters: result.filters,
          startDate: result?.startDate,
          endDate: result?.endDate,
          startDateJS: result?.startDateJS,
          endDateJS: result?.endDateJS,
          startDateISO: result?.startDateISO,
          endDateISO: result?.endDateISO,
        });
      }
    });
  }

  closeDatePicker() {
    this.dialogRef?.close();
  }
}
