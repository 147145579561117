import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize, map, tap } from 'rxjs/operators';
import {
  PRICING_MODEL_SUSPEND,
  SUSPEND_REMITTANCE_PLATFORM,
  SUSPEND_TRANSACTION,
} from '../../../../shared/constants/apis-list';
import { DataService } from '../../../../shared/services';

@Component({
  selector: 'app-suspend-transaction-modal-confirmation',
  templateUrl: './suspend-transaction-modal-confirmation.component.html',
  styleUrls: ['./suspend-transaction-modal-confirmation.component.scss'],
})
export class SuspendTransactionModalConfirmationComponent implements OnInit {
  @Input() transaction: any;
  @Input() isSuspend?: boolean;

  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  suspend: string = 'transaction';
  isLoading: boolean = false;

  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  onConfirmPress() {
    if (this.suspend === 'transaction') {
      this.onSuspendTransaction();
    } else if (this.suspend === 'user') {
    } else if (this.suspend === 'remittance') {
      // this.onSuspendRemittance();
    }
  }

  private onSuspendTransaction() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dataService
      .fetchData({
        apiUrl: SUSPEND_TRANSACTION.replace('{id}', this.transaction.id),
        method: 'PUT',
        contentType: 'application/json',
        body: null,
      })
      .pipe(
        tap((response) => {
          console.log('response: ', response);
          if (response.status == 'success' || response.status === 'OK') {
            this.modal?.dismiss();
            setTimeout(() => {
              window.location.reload();
            }, 600);
          } else {
            this.toastrService.error(response.message, response.error);
          }
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe();
  }

  private onSuspendRemittance() {
    this.dataService
      .fetchData({
        apiUrl: SUSPEND_REMITTANCE_PLATFORM.replace(
          '{id}',
          this.transaction.id
        ),
        method: 'PUT',
        contentType: 'application/json',
        body: null,
      })
      .pipe(
        tap((response) => {
          console.log('response: ', response);
          if (response.status == 'success' || response.status === 'OK') {
            this.toastrService.success(response.message);
            setTimeout(() => {
              this.modal?.dismiss();
            }, 1000);
          } else {
            this.toastrService.error(response.message, response.error);
          }
        })
      )
      .subscribe();
  }
}
