import { APIResultResponse } from './api-response.interface';
import { IUser } from './User';

export const KYCType = {
  IDENTIFICATION: 'identification',
  IDENTITY: 'identity',
  COMPLIANCE_CHECK: 'compliance_check',
  ADDITIONAL_INFORMATION: 'additional_information',
  AML: 'aml',
  FINAL: 'final',
};

export const CredentialsType = {
  ID: 'id',
  FACEMAPE: 'facemap',
  SELFIE: 'selfie',
};

export const CredentialsSubType = {
  FACE: 'face',
  FRONT: 'front',
  ORIGINAL_FRONT: 'original_front',
  PHOTO: 'photo',
  BACK: 'back',
  LIVENESS: 'liveness',
};

export type IKYCType =
  | 'identification'
  | 'identity'
  | 'compliance_check'
  | 'additional_information'
  | 'aml'
  | 'final';

export type ComplianceCredentialsType = 'id' | 'facemap' | 'selfie';
export type ComplianceCredentialsSubType =
  | 'face'
  | 'front'
  | 'back'
  | 'liveness';
export interface Compliance {
  attachments: IComplianceAMLAttachment[];
  completed_aml_check: boolean;
  current_day_cumulative_amount_received: number;
  current_month_cumulative_amount_received: number;
  current_year_cumulative_amount_received: number;
  date_of_birth: string;
  extra_data: any;
  father_name: string;
  first_name: string;
  first_name_en: string;
  id: string;
  id_issuance_country: string;
  last_edited_by: LastEditedBy;
  last_name: string;
  last_name_en: string;
  nationality: string;
  solved: boolean;
  total_cumulative_amount_received: number;
  updated_at: string;
  user_id: string;
  using_jumio_v2: boolean;
  verification_comments: string;
  verification_comments_blf: string;
  verification_status: string;
  verified_by_blf_at: string;
  verified_by_jumio_at: string;
  app_user_uuid: string;
}

export interface LastEditedBy {
  first_name: string;
  id: number;
  last_name: string;
  position: string;
}

export interface IComplianceAMLAttachment {
  content_type: string;
  doc_type: string;
  id: string;
  uploaded_at: string;
  uploaded_by: LastEditedBy;
  url: string;
}

export interface ComplianceCredentials {
  id: number;
  type: ComplianceCredentialsType;
  subtype: ComplianceCredentialsSubType;
  image: string;
  workflow_id: string;
  datetime_taken: string;
  datetime_created: string;
  datetime_updated: string;
}

export interface ComplianceVerifications {
  type: IKYCType;
  status: string;
  verified_data: any | null;
  decision: {
    result: string;
    datetime_performed: string;
  } | null;
  provider: {
    code: string;
    display_name: string;
    version: string;
  };
}

export type VerificationType =
  | 'passed'
  | 'failed'
  | 'fully-verified'
  | 'verified'
  | 'pending'
  | 'unverified'
  | 'un-verified'
  | 'in-progress'
  | 'warning'
  | 'done';

export type eKYCType =
  | 'identification'
  | 'identity'
  | 'compliance_check'
  | 'additional_information'
  | 'aml'
  | 'final';

export type VerifiedStatus = 'pending' | 'in-progress' | 'done';

export type VerifiedResult = 'passed' | 'failed' | 'warning';

export type VerifiedDecistion = {
  datetime_performed: string;
  result: VerifiedResult | null;
};

export type VerifiedData = {
  decision: VerifiedDecistion | null;
  provider?: {
    code: string;
    display_name: string;
  } | null;
  status: VerifiedStatus;
};

export type FinalVerifiedData = {
  additional_information: VerifiedData;
  aml: VerifiedData;
  compliance_check: VerifiedData;
  identification: VerifiedData;
  identity: VerifiedData;
  final: VerifiedData;
};

export type EKYCVerificationResult = {
  decision: VerifiedDecistion | null;
  status: VerifiedStatus | null;
  type: eKYCType | null;
  verified_data:
    | VerifiedData
    | FinalVerifiedData
    | Record<string, object>
    | null;
};

export type IdentificationExtractedData = {
  date_of_birth: string;
  document_number: string;
  expiry_date: string;
  first_name: string;
  first_name_en: string;
  full_name: string;
  gender: string;
  id_issuance_date: string;
  issuing_country: string;
  last_name: string;
  last_name_en: string;
  nationality: string;
  type: string;
};

export type IdentificationVerifiedData = {
  credentials: any[];
  data_consistency_check: {
    enabled: boolean;
    fields: [];
  };
  extracted_data: IdentificationExtractedData | null;
  id_photo_tampering_detection: {
    enabled: boolean;
    score: number;
  };
  id_print_detection: {
    enabled: boolean;
    score: number;
  };
  id_screen_detection: {
    enabled: boolean;
    score: number;
  };
  mrz_checksum: Record<string, object>;
};

export type IdentityExtractedData = {
  datetime_created: string;
  datetime_taken: string;
  datetime_updated: string;
  id: number;
  image: string;
  subtype: string;
  type: string;
  workflow_id: string;
};

export type IdentityVerifiedData = {
  biometric: {
    enabled: boolean;
    match_level: number;
    type: string;
  };
  credentials: IdentityExtractedData[];
};

export type IdentityFormattedExtractedData = {
  front: IdentityExtractedData;
  photo: IdentityExtractedData;
  face: IdentityExtractedData;
};

export type eKYCVerificationsAPIResponse = Omit<
  APIResultResponse,
  'results'
> & {
  results: EKYCVerificationResult[];
};
