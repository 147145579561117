import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-transaction-status-renderer',
  templateUrl: './transaction-status-renderer.component.html',
  styleUrls: ['./transaction-status-renderer.component.scss'],
})
export class TransactionStatusRendererComponent implements OnInit {
  options?: any[] = [];
  isSelectedArr: any = [];

  @Output() onSearchInputChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.options = [
      { value: 'created', name: 'Created' },
      { value: 'completed', name: 'Completed' },
      { value: 'returned', name: 'Returned' },
      { value: 'accepted', name: 'Accepted' },
      { value: 'rejected', name: 'Rejected' },
      { value: 'expired', name: 'Expired' },
    ];
  }

  onSearchInputChanged(elem: any, value: string, filter?: string): void {
    value = value.toLowerCase();
    let new_filter: any = null;

    // if (filter) {
    //   if (filter === 'remittance_platform_name') {
    //     new_filter = 'remittance_platform';

    //     if (value === 'all') {
    //       this.remittanceCodeKeys = this.remittanceCodeKeysCopy;
    //     } else {
    //       this.remittanceCodeKeys = _.filter(
    //         this.remittanceCodeKeysCopy,
    //         (item: any) => {
    //           return lowerCase(item.platform_name) === value;
    //         }
    //       );
    //     }
    //   } else if (filter === 'branch_dto') {
    //     new_filter = 'cash_out_partner';
    //   } else if (filter === 'code') {
    //     new_filter = 'pricing_model_code';
    //   } else {
    //     new_filter = filter;
    //   }
    // } else {
    //   new_filter = filter;
    // }

    this.onSearchInputChange.emit({
      elem,
      value,
      filter: new_filter,
    });

    this.isSelectedArr.push(new_filter);
  }
}
