<div class="text-right px-2 pt-2 cursor" mat-dialog-close>
  <img
    class="w-15"
    src="/assets/images/purpl/close-icon.png"
    alt="close-icon"
  />
</div>
<mat-dialog-content>
  <div class="col-md-12 mb-4">
    <div class="KYC">KYC Info</div>
    <div class="col-md-12 d-flex p-0">
      <div class="col-md-6 p-0">
        <div class="d-flex">
          <div class="col-6 py-3 px-0">
            <div
              class="verification"
              [ngClass]="{
                'not-verified': !getVerificationStatus()
              }"
            >
              <div class="status">
                <div style="color: '#44546A'" class="mb-2">Status</div>
                <span>
                  <img
                    src="./assets/images/svg/unverified.svg"
                    *ngIf="!getVerificationStatus()"
                  />
                  <img
                    src="./assets/images/svg/verified.svg"
                    *ngIf="getVerificationStatus()"
                  />
                  {{ getVerificationStatus() ? "Verified" : "Not Verified" }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 py-3 px-0">
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">KYC provider</div>
              <div class="ml-3 sub-details-val">
                {{ provider?.display_name }} {{ version }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 p-0">
            <div class="fixed-details-height">
              <div class="sub-details-heading">First Name</div>
              <div class="sub-details-val">
                {{ getFirstName(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="sub-details-heading">Last Name</div>
              <div class="sub-details-val">
                {{ getLastName(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="sub-details-heading">DOB</div>
              <div class="sub-details-val">
                {{ getDateOfBirth(jumioUser) }}
                <img
                  src="./assets/images/svg/verified.svg"
                  *ngIf="MRZDOB === true"
                  ngbTooltip="MRZ Matches"
                />
                <img
                  src="./assets/images/svg/warning.svg"
                  *ngIf="MRZDOB === false"
                  ngbTooltip="MRZ doesn't match"
                />
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="sub-details-heading">City</div>
              <div class="sub-details-val">
                {{ (jumioUser?.idCity | titlecase) || "---" }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="sub-details-heading">Country</div>
              <div class="sub-details-val">
                {{ getIssuingCountry(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="sub-details-heading">Scan Reference</div>
              <div
                class="d-flex flex-row align-items-center cursor"
                (click)="copyText(getScanReference())"
              >
                <div class="sub-details-heading">{{ getScanReference() }}</div>
                <i class="fa fa-clipboard" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="col-6 p-0">
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">ID Type</div>
              <div class="ml-3 sub-details-val">
                {{ getIDType(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">ID Subtype</div>
              <div class="ml-3 sub-details-val">
                {{ getIDSubType(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">Issuing Country</div>
              <div class="ml-3 sub-details-val">
                {{ getIssuingCountry(jumioUser) }}
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">Expiry Date</div>
              <div class="ml-3 sub-details-val">
                {{ getExpiryDate(jumioUser) }}

                <img
                  src="./assets/images/svg/verified.svg"
                  *ngIf="MRZDateExpiry === true"
                  ngbTooltip="MRZ Matches"
                />
                <img
                  src="./assets/images/svg/warning.svg"
                  *ngIf="MRZDateExpiry === false"
                  ngbTooltip="MRZ doesn't match"
                />
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">Document Number</div>
              <div class="ml-3 sub-details-val">
                {{ getDocumentNumber(jumioUser) }}
                <img
                  src="./assets/images/svg/verified.svg"
                  *ngIf="MRZDocNumber === getDocumentNumber(jumioUser)"
                  ngbTooltip="MRZ Matches"
                />
                <img
                  src="./assets/images/svg/warning.svg"
                  *ngIf="MRZDocNumber !== getDocumentNumber(jumioUser)"
                  ngbTooltip="MRZ doesn't match"
                />
              </div>
            </div>
            <div class="fixed-details-height">
              <div class="ml-3 sub-details-heading">Purpl User ID</div>
              <div class="sub-details-val cursor">
                <div class="ml-3 sub-details-heading">{{ userCode }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-0">
        <div class="col-md-12 mb-2">
          <div class="d-flex">
            <div class="img-placeholder" *ngIf="verificationImages?.front">
              <img
                #frontImg
                [src]="verificationImages?.front"
                alt="front"
                class="verification-img"
                (error)="frontImg.src = './assets/images/product-list/1.jpg'"
                (click)="
                  lgImage.img = verificationImages?.front;
                  lgImage.class = 'cover'
                "
              />
            </div>
            <div class="img-placeholder" *ngIf="verificationImages?.back">
              <img
                #backImg
                [src]="verificationImages?.back"
                alt="back"
                class="verification-img"
                (error)="backImg.src = './assets/images/product-list/1.jpg'"
                (click)="
                  lgImage.img = verificationImages?.back;
                  lgImage.class = 'cover'
                "
              />
            </div>
            <div class="img-placeholder" *ngIf="verificationImages?.face">
              <img
                #faceImg
                [src]="verificationImages?.face"
                alt="face"
                class="verification-img"
                (error)="faceImg.src = './assets/images/product-list/1.jpg'"
                (click)="
                  lgImage.img = verificationImages?.face;
                  lgImage.class = 'scale'
                "
              />
            </div>
            <div class="img-placeholder" *ngIf="verificationImages?.original">
              <img
                #originalImg
                [src]="verificationImages?.original"
                alt="original"
                class="verification-img"
                (error)="originalImg.src = './assets/images/product-list/1.jpg'"
                (click)="
                  lgImage.img = verificationImages?.original;
                  lgImage.class = 'scale'
                "
              />
            </div>
            <div class="img-placeholder" *ngIf="verificationImages?.photo">
              <img
                #photoImg
                [src]="verificationImages?.photo"
                alt="photo"
                class="verification-img"
                (error)="photoImg.src = './assets/images/product-list/1.jpg'"
                (click)="
                  lgImage.img = verificationImages?.photo;
                  lgImage.class = 'scale'
                "
              />
            </div>
            <ng-container *ngIf="livenessImages">
              <div class="img-placeholder" *ngFor="let image of livenessImages">
                <img
                  #livenessImg
                  [src]="image.image"
                  alt="liveness"
                  class="verification-img"
                  (error)="
                    livenessImg.src = './assets/images/product-list/1.jpg'
                  "
                  (click)="lgImage.img = image.image; lgImage.class = 'scale'"
                />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-12">
          <div class="lg-img-placeholder">
            <img
              [src]="
                lgImage?.img
                  ? lgImage?.img
                  : './assets/images/product-list/1.jpg'
              "
              alt="large-img"
              class="lg-verification-img"
              [ngClass]="{
                cover: lgImage.class == 'cover',
                scale: lgImage.class == 'scale'
              }"
            />
          </div>
        </div>

        <!-- data_consistency_check -->
        <div class="col-md-12 mt-3" *ngIf="provider?.display_name === 'Uqudo'">
          <div class="d-flex">
            <div class="col-md-6">
              <div class="fixed-details-height">
                <div class="sub-details-heading">No Screen capture</div>
                <div class="sub-details-val" style="font-size: 14px">
                  <span
                    style="color: '#DB4147'"
                    *ngIf="screenCapture === false"
                    >{{ screenDetectionScore + "% " }}</span
                  >
                  <span
                    style="color: '#6BA784'"
                    *ngIf="screenCapture === true"
                    >{{ screenDetectionScore + "% " }}</span
                  >
                  <span>
                    <img
                      src="./assets/images/svg/unverified.svg"
                      *ngIf="screenCapture === false"
                    />
                    <img
                      src="./assets/images/svg/verified.svg"
                      *ngIf="screenCapture === true"
                    />
                  </span>
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="sub-details-heading">Genuine ID photo</div>
                <div class="sub-details-val" style="font-size: 14px">
                  <span style="color: '#DB4147'" *ngIf="genuineID === false">{{
                    IDPhotoTamperingDetectionScore + "% "
                  }}</span>
                  <span style="color: '#6BA784'" *ngIf="genuineID === true">{{
                    IDPhotoTamperingDetectionScore + "% "
                  }}</span>
                  <span>
                    <img
                      src="./assets/images/svg/unverified.svg"
                      *ngIf="genuineID === false"
                    />
                    <img
                      src="./assets/images/svg/verified.svg"
                      *ngIf="genuineID === true"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="fixed-details-height">
                <div class="ml-3 sub-details-heading">No Printed copy</div>
                <div class="ml-3 sub-details-val" style="font-size: 14px">
                  <span style="color: '#DB4147'" *ngIf="noPrinted === false">{{
                    IDPrintDetectionScore + "% "
                  }}</span>
                  <span style="color: '#6BA784'" *ngIf="noPrinted === true">{{
                    IDPrintDetectionScore + "% "
                  }}</span>

                  <span>
                    <img
                      src="./assets/images/svg/unverified.svg"
                      *ngIf="noPrinted === false"
                    />
                    <img
                      src="./assets/images/svg/verified.svg"
                      *ngIf="noPrinted === true"
                    />
                  </span>
                </div>
              </div>
              <div class="fixed-details-height">
                <div class="ml-3 sub-details-heading">
                  Biometric Match Level
                </div>
                <div class="ml-3 sub-details-val" style="font-size: 14px">
                  <span style="color: '#DB4147'" *ngIf="biometric === false">{{
                    faceMatchLevel + "% "
                  }}</span>
                  <span style="color: '#6BA784'" *ngIf="biometric === true">{{
                    faceMatchLevel + "% "
                  }}</span>
                  <span>
                    <img
                      src="./assets/images/svg/unverified.svg"
                      *ngIf="biometric === false"
                    />
                    <img
                      src="./assets/images/svg/verified.svg"
                      *ngIf="biometric === true"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->
      </div>
    </div>
  </div>
</mat-dialog-content>
