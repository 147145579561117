import { find, isEmpty, isNull, isUndefined } from 'lodash';
import {
  EKYCVerificationResult,
  FinalVerifiedData,
  IdentificationExtractedData,
  IdentificationVerifiedData,
  VerificationType,
  VerifiedResult,
  VerifiedStatus,
} from '../interfaces/Compliance';
import { VERIFICATION } from '../constants/constant-list';
import { FormCommonValue } from '../interfaces/form.entities';

const _identification = 'identification';
const __identity = 'identity';
const _compliance_check = 'compliance_check';
const _additional_info = 'additional_information';
// const _aml = 'aml';
const _final = 'final';

const getIdentityEKYCStatus = (verified_data: FinalVerifiedData) => {
  let identityStatus: VerificationType | null = null;

  const identity = verified_data?.identity;
  const identification = verified_data?.identification;

  const identity_status = identity?.status; //check status pending | in-progress | done
  const identityResult = identity?.decision?.result; //check result passed | failed | warning | null

  const identificationStatus = identification?.status;
  const identificationResult = identification?.decision?.result;

  if (
    identityResult === VERIFICATION.passed &&
    identificationResult === VERIFICATION.passed &&
    identity_status === VERIFICATION.done &&
    identificationStatus === VERIFICATION.done
  ) {
    identityStatus = VERIFICATION.done as VerificationType;
  } else if (
    identityResult === VERIFICATION.failed &&
    identificationResult === VERIFICATION.failed &&
    identity_status === VERIFICATION.done &&
    identificationStatus === VERIFICATION.done
  ) {
    identityStatus = VERIFICATION.failed as VerificationType;
  } else if (
    identityResult === VERIFICATION.warning &&
    identificationStatus === VERIFICATION.pending &&
    identity_status === VERIFICATION.pending &&
    identificationResult === VERIFICATION.warning
  ) {
    identityStatus = VERIFICATION.pending as VerificationType;
  } else if (
    identity_status === VERIFICATION.in_progress &&
    (isNull(identityResult) || isUndefined(identityResult)) &&
    identificationStatus === VERIFICATION.in_progress &&
    (isNull(identificationResult) || isUndefined(identificationResult))
  ) {
    identityStatus = VERIFICATION.in_progress as VerificationType;
  } else if (
    identityResult === VERIFICATION.failed &&
    identificationResult === VERIFICATION.failed &&
    identity_status === VERIFICATION.pending &&
    identificationStatus === VERIFICATION.pending
  ) {
    identityStatus = VERIFICATION.failed as VerificationType;
  } else {
    identityStatus = VERIFICATION.pending as VerificationType; //purpose not to show the accepted/rejected widget if null
  }

  return identityStatus;
};

const getComplianceStatus = (verified_data: FinalVerifiedData) => {
  let status: VerificationType | null = null;

  const compliance_check = verified_data?.compliance_check;
  const complianceCheckStatus = compliance_check?.status as VerifiedStatus;
  const complianceCheckResult = compliance_check?.decision
    ?.result as VerifiedResult;

  if (complianceCheckStatus === VERIFICATION.in_progress) {
    status = VERIFICATION.in_progress as VerificationType;
  } else if (
    complianceCheckResult === VERIFICATION.warning ||
    complianceCheckStatus === VERIFICATION.pending
  ) {
    status = VERIFICATION.pending as VerificationType;
  } else if (
    complianceCheckStatus === VERIFICATION.done &&
    complianceCheckResult === VERIFICATION.passed
  ) {
    status = VERIFICATION.done as VerificationType;
  } else if (
    complianceCheckStatus === VERIFICATION.done &&
    complianceCheckResult === VERIFICATION.failed
  ) {
    status = VERIFICATION.failed as VerificationType;
  } else {
    status = VERIFICATION.failed as VerificationType;
  }

  return status;
};

const getAMLStatus = (verified_data: FinalVerifiedData) => {
  let status: VerificationType | null = null;

  const aml = verified_data?.aml;
  const aml_status: VerifiedStatus = aml?.status;
  const amlResult = aml?.decision?.result as VerifiedResult;

  if (
    amlResult === VERIFICATION.warning &&
    (aml_status === VERIFICATION.in_progress ||
      aml_status === VERIFICATION.pending)
  ) {
    status = VERIFICATION.pending as VerificationType;
  } else if (aml_status === VERIFICATION.pending && isUndefined(amlResult)) {
    status = VERIFICATION.pending as VerificationType;
  } else if (
    aml_status === VERIFICATION.done &&
    amlResult === VERIFICATION.passed
  ) {
    status = VERIFICATION.done as VerificationType;
  } else if (
    aml_status === VERIFICATION.done &&
    amlResult === VERIFICATION.failed
  ) {
    status = VERIFICATION.failed as VerificationType;
  } else {
    status = VERIFICATION.failed as VerificationType;
  }

  return status;
};

const getAdditionalStatus = (verified_data: FinalVerifiedData) => {
  let status: VerificationType | null = null;

  const additional_information = verified_data?.additional_information;
  const additionalInformationStatus: VerifiedStatus =
    additional_information?.status;
  const additionalInformationResult = additional_information?.decision
    ?.result as VerifiedResult;

  if (additionalInformationStatus === VERIFICATION.in_progress) {
    status = VERIFICATION.in_progress as VerificationType;
  } else if (
    additionalInformationResult === VERIFICATION.warning ||
    additionalInformationStatus === VERIFICATION.pending
  ) {
    status = VERIFICATION.pending as VerificationType;
  } else if (
    additionalInformationStatus === VERIFICATION.done &&
    additionalInformationResult === VERIFICATION.passed
  ) {
    status = VERIFICATION.done as VerificationType;
  } else if (
    additionalInformationStatus === VERIFICATION.done &&
    additionalInformationResult === VERIFICATION.failed
  ) {
    status = VERIFICATION.failed as VerificationType;
  } else {
    status = VERIFICATION.failed as VerificationType;
  }

  return status;
};

export const getUserExtendedKYCInformation = (
  submittedResults: EKYCVerificationResult[]
) => {
  const identificationInfo = find(submittedResults, {
    type: _identification,
  });
  const _additionalInfo = find(submittedResults, {
    type: _additional_info,
  });
  const _indentityInfo = find(submittedResults, {
    type: __identity,
  }) as EKYCVerificationResult;
  const _complianceCheck = find(submittedResults, {
    type: _compliance_check,
  });
  const _finalInfo = find(submittedResults, {
    type: _final,
  });
  // const _amlInfo = find(submittedResults, {
  //   type: _aml,
  // });

  let verificationStatus: VerificationType | null = null;
  let verifiedData: Record<string, FormCommonValue> | null | undefined = null;

  const decision = _finalInfo?.decision?.result;
  const status = _finalInfo?.status;
  const verified_data = _finalInfo?.verified_data as FinalVerifiedData;

  if (!isEmpty(_additionalInfo)) {
    const verified_data = _additionalInfo?.verified_data;
    verifiedData = {
      ...(verifiedData as any),
      ...verified_data,
    };
  }

  if (!isEmpty(identificationInfo)) {
    const _verified_data =
      identificationInfo?.verified_data as IdentificationVerifiedData;
    const extracted_data =
      _verified_data?.extracted_data as IdentificationExtractedData;

    verifiedData = {
      ...verifiedData,
      ...extracted_data,
    };
  }

  if (
    decision === VERIFICATION.passed &&
    !isNull(decision) &&
    status === VERIFICATION.done
  ) {
    verificationStatus = VERIFICATION.verified as VerificationType;
  } else if (
    status === VERIFICATION.pending ||
    decision === VERIFICATION.warning ||
    isNull(decision)
  ) {
    verificationStatus = VERIFICATION.pending as VerificationType;
  } else if (
    status === VERIFICATION.in_progress ||
    decision === VERIFICATION.warning
  ) {
    verificationStatus = VERIFICATION.in_progress as VerificationType;
  } else if (
    (decision === VERIFICATION.failed || isNull(decision)) &&
    status === VERIFICATION.done
  ) {
    verificationStatus = VERIFICATION.failed as VerificationType;
  }

  const identityStatus = getIdentityEKYCStatus(verified_data);
  const complianceStatus = getComplianceStatus(verified_data);
  const amlStatus = getAMLStatus(verified_data);
  const additionalInfoStatus = getAdditionalStatus(verified_data);

  return {
    verificationStatus,
    identityStatus,
    complianceStatus,
    amlStatus,
    additionalInfoStatus,
    verifiedData,
    identificationInfo,
    additionalInfo: _additionalInfo,
    indentityInfo: _indentityInfo,
    complianceCheck: _complianceCheck,
    finalInfo: _finalInfo,
  };
};
