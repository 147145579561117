<div *ngIf="params.filter !== 'completedAmlCheck'">
  <mat-form-field>
    <input
      matInput
      name=""
      type="text"
      class="form-control"
      id=""
      placeholder=""
      (input)="filterSearchText.next($event)"
      [(ngModel)]="filterText"
    />
  </mat-form-field>
</div>

<div
  *ngIf="params.filter === 'completedAmlCheck'"
  class="d-flex flex-row align-items-center justify-content-center test"
></div>
