import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as React from 'react';
import { EVENT_TYPE, RangedSelection } from 'react-infinite-calendar';
import { PurplCalendar, PurplCalendarProps } from './purple-calendar';
import { createRoot, Root } from 'react-dom/client';

export type DateType = Date | string | number;
export interface DateRange {
  start: DateType;
  end: DateType;
}

@Component({
  selector: 'app-purple-calendar',
  template: `<span #InfiniteCalendar></span>`,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./purple-calendar.component.scss'],
})
export class PurpleCalendarComponent
  implements OnChanges, OnDestroy, AfterViewInit, OnInit
{
  @ViewChild('InfiniteCalendar', { static: true }) containerRef!: ElementRef;

  @Input() public selected?:
    | DateType
    | false
    | DateRange
    | DateType[]
    | undefined;
  @Output() public selectedChange = new EventEmitter<RangedSelection>();

  root: Root | undefined;

  constructor() {
    this.handleDateSelected = this.handleDateSelected.bind(this);
  }

  public handleDateSelected(ev: RangedSelection) {
    if (ev.eventType !== EVENT_TYPE.HOVER) {
      if (this.selectedChange) {
        this.selectedChange.emit(ev);
        this.render();
      }
    }
  }

  ngOnInit(): void {
    if (this.root === undefined) {
      this.root = createRoot(this.containerRef.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    this.render();
  }

  ngAfterViewInit() {
    if (this.root === undefined) {
      this.root = createRoot(this.containerRef.nativeElement);
    }
    this.render();
  }

  ngOnDestroy() {
    this.root?.unmount();
  }

  protected getProps(): PurplCalendarProps {
    const { selected, handleDateSelected } = this;

    return {
      selected,
      onSelect: handleDateSelected,
    };
  }

  private render() {
    console.log('render');
    this.root?.render(React.createElement(PurplCalendar, this.getProps()));
  }
}
