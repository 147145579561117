import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import FEATURES from '../constants/navigation';
import { User } from '../interfaces/User';
import { UserService } from './user.service';
import {
  BLF_BRAND_MANAGER,
  ROLE_ADMIN,
  ROLE_PARTNER,
} from '../constants/constant-list';
// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  roles?: string[];
  name?: string;
  isAdmin?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar = false;
  public user?: User | null = null;

  title: string;

  MENUITEMS: Menu[] = [];

  constructor(public userService: UserService) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    this.user = this.userService.user;

    this.title =
      this.user?.position === ROLE_ADMIN ||
      this.user?.position === BLF_BRAND_MANAGER ||
      this.user?.position === ROLE_PARTNER
        ? 'Dashboard'
        : 'Cash out Transaction';

    this.MENUITEMS.push(
      {
        title: this.title,
        icon: 'dashboard',
        name: FEATURES.DASHBOARD.name,
        type: 'link',
        active: false,
        isAdmin: true,
        path: '/dashboard',
      },
      {
        title: 'Accepted Transaction',
        path: '/accepted-transaction',
        icon: 'compliance',
        type: 'link',
        active: false,
        isAdmin: false,
        name: FEATURES.ACCEPTED_TRANSACTION.name,
      },
      {
        title: 'Brand Profile',
        icon: 'entities',
        type: 'sub',
        active: false,
        isAdmin: false,
        name: FEATURES.BRAND_PROFILE.name,
        children: [
          {
            path: '/brand-profile/cashout-points',
            title: 'Cashout Points Listing',
            type: 'link',
            name: FEATURES.BRAND_PROFILE_CASHOUT_POINT_LISTING.name,
          },
          {
            path: '/brand-profile/cashout-points/add',
            title: 'Add New Cashout Point',
            type: 'link',
            name: FEATURES.BRAND_PROFILE_CASHOUT_POINT_ADD.name,
          },
        ],
      },
      {
        title: 'Entities',
        icon: 'entities',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.ENTITIES.name,
        children: [
          {
            path: '/entities/brands',
            title: 'Entities Listing',
            type: 'link',
            name: FEATURES.ENTITIES_LISTING.name,
          },
          {
            path: '/entities/brands/add',
            title: 'Add New Brand',
            type: 'link',
            name: FEATURES.ENTITIES_ADD.name,
          },
        ],
      },
      {
        title: 'Merchants',
        path: 'merchants',
        icon: '',
        type: 'link',
        active: false,
        isAdmin: true,
        name: FEATURES.MERCHANTS.name,
        children: [
          {
            path: '/merchants/add',
            title: 'Add New Merchant',
            type: 'link',
            name: FEATURES.MERCHANT_ADD.name,
          },
        ],
      },
      {
        title: 'Partners',
        path: 'partners',
        icon: '',
        type: 'link',
        active: false,
        isAdmin: true,
        name: FEATURES.PARTNERS.name,
      },
      {
        name: FEATURES.NGOs.name,
        children: [
          {
            path: '/ngos/add',
            title: 'Add New NGO',
            type: 'link',
            name: FEATURES.NGO_ADD.name,
          },
        ],
      },
      {
        title: 'NGOs',
        path: 'ngos',
        icon: 'heart',
        type: 'link',
        active: false,
        isAdmin: true,
        name: FEATURES.NGOs.name,
        children: [
          {
            path: '/ngos/add',
            title: 'Add New NGO',
            type: 'link',
            name: FEATURES.NGO_ADD.name,
          },
        ],
      },

      {
        title: 'Brand CMS Users',
        icon: 'app-users',
        type: 'sub',
        active: false,
        isAdmin: false,
        name: FEATURES.BRAND_CMS_USERS.name,
        children: [
          {
            path: '/users/tellers',
            title: 'CMS Users',
            type: 'link',
            name: FEATURES.BRAND_CMS_USERS_LISTING.name,
          },
          {
            path: '/users/tellers/add',
            title: 'Add New CMS User',
            type: 'link',
            name: FEATURES.BRAND_CMS_USERS_ADD.name,
          },
        ],
      },
      {
        title: 'Analytics',
        path: '/analytics',
        icon: 'bar-chart',
        type: 'link',
        active: false,
        isAdmin: false,
        name: FEATURES.ANALYTICS.name,
      },
      {
        title: 'Users',
        icon: 'app-users',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.USERS.name,
        children: [
          {
            path: '/users/app-users',
            title: 'App Users',
            type: 'link',
            name: FEATURES.USERS_APP_USERS.name,
          },
          {
            path: '/users/unregistered-app-users',
            title: 'Unregistered Beneficiary',
            type: 'link',
            name: FEATURES.USERS_UNREGISTERED_BENEFICIARY.name,
          },
          {
            path: '/users/app-users-feedbacks',
            title: 'App Users Feedbacks',
            type: 'link',
            name: FEATURES.USERS_APP_USER_FEEDBACK.name,
          },
          {
            path: '/users/cms-users',
            title: 'CMS Users Listing',
            type: 'link',
            name: FEATURES.USERS_CMS_USERS_LISTING.name,
          },
          {
            path: '/users/cms-users/add',
            title: 'Add New CMS User',
            type: 'link',
            name: FEATURES.USERS_CMS_USERS_ADD.name,
          },
          {
            path: '/users/senders',
            title: 'Senders',
            type: 'link',
            name: FEATURES.USERS_SENDERS.name,
          },
          {
            path: '/users/roles-access',
            title: 'Roles & Access',
            type: 'link',
            name: FEATURES.USERS_ROLES_ACCESS.name,
          },
        ],
      },
      {
        title: 'App Settings',
        icon: 'app-settings',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.APP_SETTINGS.name,
        children: [
          {
            path: '/app-settings/home-page',
            title: 'Home Page',
            type: 'link',
            name: FEATURES.APP_SETTINGS_HOMEPAGE.name,
          },
          {
            path: '/app-settings/privacy-policy',
            title: 'Privacy Policy',
            type: 'link',
            name: FEATURES.APP_SETTINGS_PRIVACY_POLICY.name,
          },
          {
            title: 'Help Center',
            type: 'sub',
            name: FEATURES.APP_SETTINGS_HELP_CENTER.name,
            children: [
              {
                path: '/app-settings/faq-topics',
                title: 'Help Center (FAQ Topic)',
                type: 'link',
                name: FEATURES.APP_SETTINGS_HELP_CENTER_FAQ_TOPICS.name,
              },
              {
                path: '/app-settings/help-center',
                title: 'Help Center (FAQs)',
                type: 'link',
                name: FEATURES.APP_SETTINGS_HELP_CENTER_FAQS.name,
              },
              {
                path: '/app-settings/faq-videos',
                title: 'Help Center (Videos)',
                type: 'link',
                name: FEATURES.APP_SETTINGS_HELP_CENTER_VIDEOS.name,
              },
            ],
          },
          {
            path: '/app-settings/terms-conditions',
            title: 'Terms & Conditions',
            type: 'link',
            name: FEATURES.APP_SETTINGS_TERMS_CONDITIONS.name,
          },
          {
            path: '/app-settings/language',
            title: 'Languages',
            type: 'sub',
            name: FEATURES.APP_SETTINGS_LANGUAGE.name,
            children: [
              {
                path: '/app-settings/languages',
                title: 'Languages',
                type: 'link',
                name: FEATURES.APP_SETTINGS_LANGUAGES.name,
              },
              {
                path: '/app-settings/languages-content',
                title: 'Language - Contents',
                type: 'link',
                name: FEATURES.APP_SETTINGS_LANGUAGES_CONTENT.name,
              },
            ],
          },
        ],
      },
      {
        title: 'Remittance Platforms',
        icon: 'remittance',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.REMITTANCE_PLATFORMS.name,
        children: [
          {
            path: '/remittance-platforms',
            title: 'Platforms Listing',
            type: 'link',
            name: FEATURES.REMITTANCE_PLATFORMS_LISTING.name,
          },
          {
            path: '/remittance-platforms/add',
            title: 'Add New Remittance',
            type: 'link',
            name: FEATURES.REMITTANCE_PLATFORMS_ADD.name,
          },
        ],
      },
      {
        title: 'Transaction Management',
        icon: 'transaction_mgt',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.TRANSACTION_MANAGEMENT.name,
        children: [
          {
            path: '/received-transactions',
            title: 'Received Transactions',
            type: 'link',
            name: FEATURES.TRANSACTION_MANAGEMENT_RECEIVED.name,
          },
          {
            path: '/cashout-transactions',
            title: 'Cashout Transactions',
            type: 'link',
            name: FEATURES.TRANSACTION_MANAGEMENT_CASHOUTS.name,
          },
          {
            path: '/revenue-transactions',
            title: 'Revenue Transactions',
            type: 'link',
            name: FEATURES.TRANSACTION_MANAGEMENT_REVENUE.name,
          },
          {
            path: '/transfers-transactions',
            title: 'P2P Transfers',
            type: 'link',
            name: FEATURES.TRANSACTION_MANAGEMENT_TRANSFERS.name,
          },
        ],
      },
      // {
      //   title: 'Transaction Mgt - Beta',
      //   path: '/transaction-beta',
      //   icon: 'transaction_mgt',
      //   type: 'link',
      //   active: false,
      //   isAdmin: true,
      //   name: FEATURES.TRANSACTION_MANAGEMENT_BETA.name,
      // },
      // {
      //   title: 'Partners Transaction',
      //   path: 'transfers',
      //   icon: '',
      //   type: 'link',
      //   active: false,
      //   isAdmin: true,
      //   name: FEATURES.TRANSFERS.name,
      // },
      {
        title: 'Compliance',
        path: '/compliance',
        icon: 'compliance',
        type: 'link',
        active: false,
        isAdmin: true,
        name: FEATURES.COMPLIANCE.name,
      },
      {
        title: 'Compliance - Beta',
        path: '/compliance-beta',
        icon: 'compliance',
        type: 'sub',
        active: false,
        isAdmin: true,
        name: FEATURES.COMPLIANCE_BETA.name,
        children: [
          {
            path: '/compliance-beta',
            title: 'Compliance Beta',
            type: 'link',
            name: FEATURES.COMPLIANCE_USERS.name,
          },
        ],
      },

      {
        title: 'Suspensions',
        path: '/suspensions',
        icon: 'block',
        type: 'link',
        active: false,
        isAdmin: true,
        name: FEATURES.SUSPENSIONS.name,
      }
    );
  }

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }
}
