<div class="p-2">
  <div class="text-right p-2 cursor" mat-dialog-close>
    <img
      class="w-15"
      src="/assets/images/purpl/close-icon.png"
      alt="close-icon"
    />
  </div>

  <div class="col-md-12 d-flex mb-4 modal-transaction">
    <div
      class="col-md-2 mr-2"
      [ngClass]="{
        'cashout-custom': transaction?.transaction_type == 'Received'
      }"
    >
      <div class="type-img">
        <div
          *ngIf="transaction?.transaction_type == 'Received'"
          class="received"
        >
          <img src="/assets/images/purpl/received-rect-2.png" alt="rect" />
          <img src="/assets/images/purpl/received-arrow-2.png" alt="arrow" />
        </div>
      </div>
    </div>
    <div
      class="col-md-4 p-0"
      [ngClass]="{ 'm-auto': transaction?.transaction_type == 'Received' }"
    >
      <div>
        <div
          class="type-name mb-1"
          *ngIf="transaction?.transaction_type == 'Received'"
        >
          {{ transaction?.transaction_type }}
        </div>
        <div class="type-status">
          {{ transaction?.transaction_status }}
        </div>
      </div>
    </div>

    <div
      class="col-md-6 pr-5 text-right"
      *ngIf="transaction?.transaction_type == 'Received'"
    >
      <div class="ref-code mt-0">Transaction ID:</div>
      <div class="ref-code-val">
        <div>
          {{ transaction?.remittance_reference_id }}
        </div>
      </div>
      <div class="ref-code mt-0">Ref Code:</div>
      <div class="ref-code-val">
        <div>
          {{ transaction?.transaction_code }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 modal-transaction">
      <div class="col-md-12 d-flex">
        <div class="col-md-6">
          <div class="heading mb-3">Transaction Details</div>
          <div *ngIf="transaction?.transaction_type == 'Received'">
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Received Amount</div>
              <div class="sub-detail">{{ transaction?.amount }}</div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Received On</div>
              <div class="sub-detail text-small">
                {{ transaction?.received_date | date : "d/M/yy" }} at
                {{ transaction?.received_date | date : "shortTime" }}
              </div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">{{ transactionStatus }} at</div>
              <div class="sub-detail text-small">
                {{ transactionDate | date : "d/M/yy" }} at
                {{ transactionDate | date : "shortTime" }}
              </div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Received From</div>
              <div class="sub-detail">{{ transaction?.sender_name }}</div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Sender Location</div>
              <div class="sub-detail">{{ transaction?.sender_location }}</div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Remittance Platform</div>
              <div class="sub-detail">
                {{ transaction?.remittance_platform_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="heading mb-3">Remittance Details</div>
          <div *ngIf="transaction?.transaction_type == 'Received'">
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Cashout Partner</div>
              <div class="sub-detail">
                {{ transaction?.branch_dto?.brand?.name }}
              </div>
            </div>
            <div class="mb-2 detail-height">
              <div class="sub-heading mb-1">Cashout Location</div>
              <div class="sub-detail">{{ cashoutLocation }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-auto">
      <button
        type="button"
        class="btn btn-danger"
        (click)="onSuspendTransaction(true)"
      >
        <span *ngIf="!transaction?.suspended">Suspend</span>
        <span *ngIf="transaction?.suspended">Unsuspend</span>
      </button>
    </div>
  </div>
</div>
