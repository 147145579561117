<div class="text-right px-2 pt-2 cursor" mat-dialog-close>
  <img
    class="w-15"
    src="/assets/images/purpl/close-icon.png"
    alt="close-icon"
  />
</div>

<mat-dialog-content>
  <lib-ngx-image-zoom
    [thumbImage]="img"
    zoomMode="hover"
    class="ngxImageZoomContainer"
  >
  </lib-ngx-image-zoom>
</mat-dialog-content>
