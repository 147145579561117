import { Injector, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import {
  DEFAULT_PAGE_INDEX,
  NUMBER_RECORDS_PER_PAGE,
} from '../constants/constant-list';
import {
  ApiResponseBodyInterface,
  ApiResponseInterface,
} from '../interfaces/api-response.interface';
import { URLQueryParams } from '../interfaces/query-params.interface';
import { BaseNetworkService } from './base-network.service';
import {
  getAllAppUsersFullfilled,
  getAllAppUsersPending,
  getAllAppUsersRejected,
  getAllUserTransactionsFullfilled,
  getAllUserTransactionsPending,
  setSelectedUser,
  setSelectedUserFeatures,
} from '../stores/slices/AppUsers';
import {
  TRANSACTION_USER_BALANCE,
  USER_BASIC_INFORMATION,
  USER_DETAIL,
  USER_FEATURES,
  USER_TRANSACTIONS,
  USER_WALLET_BALANCE,
} from '../constants/apis-list';
import { find, has, isEmpty, isUndefined, uniq, uniqBy } from 'lodash';
import {
  FormCommonValue,
  FormFieldsSchema,
  FormSchema,
  FormSchemaProps,
  FormSchemaTypes,
  FormSchemaTypesV2,
  FormSchemaV2,
  OnyIfFieldProps,
} from '../interfaces/form.entities';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { setFormValues } from '../stores/slices/EKYC';

@Injectable({
  providedIn: 'root',
})
export class AppUsersService extends BaseNetworkService {
  isSortedDesc: boolean = true;

  constructor(private injector: Injector, private store: Store) {
    super(injector);
  }

  private FormSchemaTypeMapper(
    type: FormSchemaTypesV2 | string
  ): FormSchemaTypes {
    switch (type) {
      case FormSchemaTypesV2.String: {
        return FormSchemaTypes.TextInput;
      }
      case FormSchemaTypesV2.Choice: {
        return FormSchemaTypes.Dropwdown;
      }
      case FormSchemaTypesV2.Boolean: {
        return FormSchemaTypes.Checkbox;
      }
      case FormSchemaTypesV2.Date: {
        return FormSchemaTypes.DatePicker;
      }
      case FormSchemaTypesV2.Time: {
        return FormSchemaTypes.TimePicker;
      }
      default:
        return FormSchemaTypes.Text;
    }
  }

  getAllAppUsers(query?: Partial<URLQueryParams> | any) {
    const requestBody: any = {
      ...query,
      from_dropdown: true,
      page: query?.page || DEFAULT_PAGE_INDEX,
      size: query?.size || NUMBER_RECORDS_PER_PAGE,
      search: query?.search || '',
    };

    this.store.dispatch(getAllAppUsersPending());
    this.dataService
      .fetchData({
        apiUrl: this.apiList.USER_LISTING,
        body: requestBody,
      })
      .pipe(
        map((response) => {
          return response && response.data ? response.data : null;
        })
      )
      .subscribe(
        (resource: ApiResponseInterface) =>
          this.store.dispatch(getAllAppUsersFullfilled(resource)),
        (error) =>
          this.store.dispatch(
            getAllAppUsersRejected({
              error: error?.response?.data || error,
            })
          )
      );
  }

  getAdditionalInfoKYC(
    userCode: string,
    formValues: Record<string, any> | undefined
  ) {
    const forms: FormSchema[] = [];
    const formKeys: string[] = [];
    let formOnlyIfKeys: string[] = [];
    let formObjSchema: any = {};
    const currentDate = moment();

    const pushToFormSchema = (key: string, value: FormSchemaProps) => {
      formKeys.push(key);

      formObjSchema = {
        ...formObjSchema,
      };

      forms.push({
        name: key,
        type: this.FormSchemaTypeMapper(value?.type),
        label: value?.label,
        disabled: value?.read_only ?? false,
        required: value?.required ?? false,
        choices: value?.choices ?? [],
        value: !isEmpty(formValues) ? formValues[key] : 'NA',
      });
    };

    return this.dataService
      .fetchData({
        apiUrl: USER_BASIC_INFORMATION.replace('{userCode}', userCode),
        method: 'OPTIONS',
      })
      .pipe(
        map((response) => {
          return response && !isEmpty(response?.actions)
            ? response?.actions[0]
            : null;
        }),
        map((response) => {
          const props: FormFieldsSchema | null = response;
          const formProperties = props?.fields as FormSchemaV2;

          if (!isEmpty(formProperties)) {
            for (const key in formProperties) {
              const value = formProperties[
                key as keyof typeof formProperties
              ] as FormSchemaProps;

              const effectiveFrom = moment(value?.effective_from);

              if (value.category === 'personal-information') {
                if (effectiveFrom.isSameOrBefore(currentDate)) {
                  pushToFormSchema(key, value);
                }
              } else if (value.category === 'job-information') {
                if (effectiveFrom.isSameOrBefore(currentDate)) {
                  if (isUndefined(value.only_if)) {
                    pushToFormSchema(key, value);
                  } else {
                    if (!isEmpty(formValues)) {
                      // We need to map the values of onlyIfs
                      const onlyIfs = value.only_if.map(
                        (i: OnyIfFieldProps) => i.value
                      );
                      value.only_if.forEach((cond: OnyIfFieldProps) => {
                        formOnlyIfKeys.push(cond.field);
                        if (has(formValues, cond.field)) {
                          const formValue = formValues![cond.field];
                          if (cond.operator === 'ne') {
                            if (!onlyIfs.includes(formValue)) {
                              pushToFormSchema(key, value);
                            }
                          } else if (cond.operator === 'eq') {
                            if (formValue === cond.value) {
                              pushToFormSchema(key, value);
                            }
                          }
                        }
                      });
                    }
                  }
                }
              } else if (value.category === 'address-information') {
                if (effectiveFrom.isSameOrBefore(currentDate)) {
                  if (isUndefined(value.only_if)) {
                    pushToFormSchema(key, value);
                  } else {
                    if (!isEmpty(formValues)) {
                      let willPushToForm = false;
                      value.only_if.forEach((cond: OnyIfFieldProps) => {
                        formOnlyIfKeys.push(cond.field);
                        if (has(formValues, cond.field)) {
                          const formValue = formValues![cond.field];
                          if (cond.operator === 'ne') {
                            willPushToForm = formValue !== cond.value;
                          } else if (cond.operator === 'eq') {
                            willPushToForm = formValue === cond.value;
                          }
                        } else {
                          willPushToForm = false;
                        }
                      });
                      if (willPushToForm) {
                        pushToFormSchema(key, value);
                      }
                    }
                  }
                }
              }
            }
          }
          formOnlyIfKeys = uniq(formOnlyIfKeys);
          const formattedForms = uniqBy(forms, 'name');
          return formattedForms;
        })
      );
  }

  getUsersVerificationInfo(userCode: string) {
    const _identification = 'identification';
    const _additionalInfo = 'additional_information';
    const additionalInfoForms: {
      name: string;
      value: string;
    }[] = [];
    let verifiedData: Record<string, FormCommonValue> | undefined;

    return this.dataService
      .fetchData({
        apiUrl: this.apiList.USER_VERIFICATION_DETAILS.replace(
          '{userCode}',
          userCode
        ),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(
        map((response) => {
          const submittedResults = response?.results;
          if (submittedResults) {
            const identificationInfo = find(submittedResults, {
              type: _identification,
            });
            const additionalInfo = find(submittedResults, {
              type: _additionalInfo,
            });

            if (!isEmpty(additionalInfo)) {
              const verified_data = additionalInfo?.verified_data;
              verifiedData = {
                ...verifiedData,
                ...verified_data,
              };

              for (const key in verified_data) {
                const value = verified_data[key] as FormCommonValue;
                additionalInfoForms.push({
                  value: value?.toString()!,
                  name: key,
                });
              }
              this.store.dispatch(setFormValues(verifiedData as any));
            }

            if (!isEmpty(identificationInfo)) {
              const extracted_data =
                identificationInfo?.verified_data?.extracted_data;

              verifiedData = {
                ...verifiedData,
                ...extracted_data,
              };
            }
          }

          return verifiedData;
        })
      );
  }

  updateComplianceInfo(id: string, data: any) {
    return this.dataService
      .fetchData({
        apiUrl: `${this.apiList.BLF_COMPLIANCE}/${id}`,
        method: 'PUT',
        contentType: 'application/json',
        body: (data = {
          ...data,
          verification_status: data.verification_status?.toUpperCase(),
        }),
      })
      .pipe(
        map((response) => {
          return response?.data ?? null;
        })
      );
  }

  updateAdditionalnfo(userCode: string, data: any) {
    return this.dataService
      .fetchData({
        apiUrl: USER_BASIC_INFORMATION.replace('{userCode}', userCode),
        method: 'PUT',
        contentType: 'application/json',
        body: (data = {
          ...data,
        }),
      })
      .pipe(
        map((response) => {
          return response?.data ?? null;
        })
      );
  }

  getAllUserTransactions(userCode: string, params?: HttpParams) {
    let _params: HttpParams = new HttpParams();
    _params = _params.set('limit', NUMBER_RECORDS_PER_PAGE);
    _params = _params.set('offset', DEFAULT_PAGE_INDEX);

    this.store.dispatch(getAllUserTransactionsPending());
    this.dataService
      .fetchData({
        apiUrl: USER_TRANSACTIONS.replace('{userCode}', userCode),
        method: 'GET',
        params: isUndefined(params?.toString()) ? _params : params,
      })
      .pipe(
        map((response) => {
          return response ?? null;
        })
      )
      .subscribe({
        next: (resource: ApiResponseBodyInterface) =>
          this.store.dispatch(getAllUserTransactionsFullfilled(resource)),
        error: (error) =>
          this.store.dispatch(
            getAllUserTransactionsPending(error?.response?.results)
          ),
      });
  }

  getWalletBalance(userCode: string) {
    return this.dataService
      .fetchData({
        apiUrl: USER_WALLET_BALANCE.replace('{userCode}', userCode),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(map((response) => response?.results ?? null));
  }

  loadTotalBalance(id: any) {
    return this.dataService
      .fetchData({
        apiUrl: TRANSACTION_USER_BALANCE.replace('{id}', String(id).toString()),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(map((response) => response?.data?.total_balance ?? null));
  }

  loadComplianceData(id: any) {
    return this.dataService
      .fetchData({
        apiUrl: USER_DETAIL.replace('{id}', String(id).toString()),
        method: 'GET',
        contentType: 'application/json',
        params: null,
        body: null,
      })
      .pipe(
        map((response) => {
          const resp = response.data;
          this.store.dispatch(setSelectedUser(resp));
          return resp;
        })
      );
  }

  loadUserFeatures(userCode: string) {
    return this.dataService
      .fetchData({
        apiUrl: USER_FEATURES.replace('{userCode}', userCode),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(
        map((response) => {
          this.store.dispatch(setSelectedUserFeatures(response));
          return response;
        })
      );
  }
}
