import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  isEmpty,
  toUpper,
  map as lodashMap,
  find,
  filter,
  includes,
  upperCase,
} from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { USER_DETAIL, VERIFICATION_IMAGES } from '../../constants/apis-list';
import { DataService } from '../../services';
import { IUser } from '../../interfaces/User';
import {
  ComplianceCredentials,
  ComplianceVerifications,
  CredentialsSubType,
  CredentialsType,
  KYCType,
} from '../../interfaces/Compliance';
import { Lightbox, LIGHTBOX_EVENT, LightboxEvent } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { ZoomImagesComponent } from '../zoom-images/zoom-images.component';

type KYCAlbum = {
  front: string | null;
  back: string | null;
  face: string | null;
  photo: string | null;
  original_front: string | null;
  liveness: string | null;
  one: string | null;
  two: string | null;
  three: string | null;
  four: string | null;
  thumb: string | null;
  src: string | null;
};

@Component({
  selector: 'modal-jumio-user-details',
  templateUrl: './jumio-user-details.component.html',
  styleUrls: ['./jumio-user-details.component.scss'],
})
export class JumioUserDetailsComponent implements OnInit {
  public album: KYCAlbum = {
    front: null,
    back: null,
    face: null,
    photo: null,
    original_front: null,
    liveness: null,
    one: null,
    two: null,
    three: null,
    four: null,
    thumb: null,
    src: null,
  };

  public lgImage: any = { img: null, class: null };
  public jumio: any;
  public jumioUser: any;

  public kycImages: ComplianceCredentials[] = [];
  public livenessImages: ComplianceCredentials[] = [];

  public identity: ComplianceVerifications | null = null;
  public identification?: ComplianceVerifications | null = null;

  private _subscription?: Subscription;

  user_id?: string;
  userCode?: string = '';
  using_jumio_v2?: boolean;
  provider?: any;
  version?: string;

  faceMatchLevel?: number;
  screenDetectionScore?: number;
  IDPhotoTamperingDetectionScore?: number;
  IDPrintDetectionScore?: number;

  biometric?: boolean;
  screenCapture?: boolean;
  genuineID?: boolean;
  noPrinted?: boolean;

  MRZDocNumber?: string;
  MRZDOB?: boolean;
  MRZDateExpiry?: boolean;
  percentageRates?: any;

  showFrontImage: boolean = false;
  showBackImage: boolean = false;
  showFaceImage: boolean = false;
  showLivenessImage: boolean = false;
  showLargeImage: boolean = false;

  userPosition?: string;

  constructor(
    private toastr: ToastrService,
    private dataService: DataService,
    private _lightbox: Lightbox,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { user_id: string; using_jumio_v2: boolean; userCode: string }
  ) {
    this.user_id = data.user_id;
    this.using_jumio_v2 = data.using_jumio_v2;
  }

  ngOnInit(): void {
    if (this.user_id) {
      this.dataService
        .fetchData({
          apiUrl: USER_DETAIL.replace('{id}', this.data.user_id),
          method: 'GET',
          contentType: 'application/json',
          params: null,
          body: null,
        })
        .pipe(map((response) => response.data))
        .subscribe((response: IUser) => {
          if (response && response?.compliance?.app_user_uuid) {
            this.userCode = response?.compliance?.app_user_uuid;

            this.loadVerification(this.userCode);
          }
        });
    }
  }

  copyText(copyText: string): void {
    try {
      navigator.clipboard.writeText(copyText);
      this.toastr.success('Copied to clipboard');
    } catch (error) {
      this.toastr.error('Error while copying text to clipboard');
    }
  }

  onImageError(frontImg: any) {
    frontImg.src = './assets/images/product-list/1.jpg';
  }

  getVerificationClass(details: any) {
    const result = this.jumio?.decision?.type;
    return !isEmpty(result) && result === 'PASSED' ? 'Verified' : '---';
  }

  getIDStatus(details: any) {
    return upperCase(this.identification?.decision?.result) ?? '---';
  }

  getIdentityStatus(details: any) {
    return this.identity?.decision?.result === 'passed' ? 'Verified' : '---';
  }

  getIDType(details: any) {
    return toUpper(details?.type) ?? '---';
  }

  getIDSubType(details: any) {
    return toUpper(details?.sub_type) ?? '---';
  }

  getDocumentNumber(details: any) {
    return toUpper(details?.document_number) ?? '---';
  }

  getFirstName(details: any) {
    return toUpper(details?.first_name) ?? '---';
  }

  getLastName(details: any) {
    return toUpper(details?.last_name) ?? '---';
  }

  getDateOfBirth(details: any) {
    return toUpper(details?.date_of_birth) ?? '---';
  }

  getIssuingCountry(details: any) {
    return toUpper(details?.issuing_country) ?? '---';
  }

  getExpiryDate(details: any) {
    return toUpper(details?.expiry_date) ?? '---';
  }

  getIDScreenDetectionScore(details: any) {
    return toUpper(details?.id_screen_detection?.score) ?? '---';
  }

  getIDPhotoDetectionScore(details: any) {
    return toUpper(details?.id_photo_tampering_detection?.score) ?? '---';
  }

  getIDPrintDetectionScore(details: any) {
    return toUpper(details?.id_print_detection?.score) ?? '---';
  }

  getBiometric(details: any) {
    return toUpper(details?.biometric?.match_level) ?? '---';
  }

  getScanReference() {
    if (this.using_jumio_v2) {
      return this.jumio?.workflow_id;
    } else {
      return this.jumio?.scan_reference;
    }
  }

  getVerificationStatus(): boolean {
    if (this.identification && this.identity) {
      const identification = this.identification?.decision?.result ?? '';
      const identity = this.identity?.decision?.result ?? '';

      return identification === 'passed' && identity === 'passed';
    }

    return false;
  }

  private loadVerification(userCode: string) {
    this.dataService
      .fetchData({
        apiUrl: VERIFICATION_IMAGES.replace('{userCode}', userCode),
        method: 'GET',
        contentType: 'application/json',
      })
      .pipe(map((response) => (response && response ? response : null)))
      .subscribe((response: any) => {
        if (response && !isEmpty(response?.results)) {
          const identification = find(response?.results, {
            type: KYCType.IDENTIFICATION,
          }) as ComplianceVerifications;
          const identity = find(response?.results, {
            type: KYCType.IDENTITY,
          }) as ComplianceVerifications;

          if (identification && identification?.verified_data) {
            this.identification = identification;
            this.provider = this.identification?.provider;
            this.version =
              this.provider?.display_name === 'Jumio'
                ? 'V' + this.identification?.provider?.version
                : '';

            this.jumioUser = {
              ...identification?.verified_data.extracted_data,
              ...identification?.verified_data,
              ...identity?.verified_data,
            };

            const credentials = identification?.verified_data?.credentials;

            this.jumio = {
              workflow_id: credentials[0]?.workflow_id,
            };

            this.getMRZChecksum();
            this.getVerificationScores();

            if (this.jumioUser) {
              const result = identification?.decision?.result;

              if (result) {
                this.jumio = {
                  jumio_verification_status:
                    result === 'PASSED' ? 'Pass' : result,
                };
              }
            }

            this.kycImages = [...(!isEmpty(credentials) ? credentials : [])];
          }

          if (identity && identity.verified_data) {
            this.identity = identity;
            const credentials = identity.verified_data?.credentials;
            this.kycImages = [...(!isEmpty(credentials) ? credentials : [])];
          }

          if (!isEmpty(this.kycImages)) {
            const face = find(this.kycImages, {
              subtype: CredentialsSubType.FACE,
              type: CredentialsType.SELFIE,
            }) as ComplianceCredentials;
            const idFront = find(this.kycImages, {
              subtype: CredentialsSubType.FRONT,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const original = find(this.kycImages, {
              subtype: CredentialsSubType.ORIGINAL_FRONT,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const photo = find(this.kycImages, {
              subtype: CredentialsSubType.PHOTO,
              type: CredentialsType.ID,
            }) as ComplianceCredentials;
            const idBack = find(this.kycImages, {
              subtype: CredentialsSubType?.BACK,
              type: CredentialsType?.ID,
            }) as ComplianceCredentials;
            this.livenessImages = filter(this.kycImages, (i) => {
              return includes(i.subtype, CredentialsSubType.LIVENESS);
            }) as ComplianceCredentials[];

            if (face) {
              this.mapVerificationImage(face?.image, CredentialsSubType.FACE);
            }

            if (idFront) {
              this.mapVerificationImage(
                idFront?.image,
                CredentialsSubType.FRONT
              );
            }

            if (original) {
              this.mapVerificationImage(
                original?.image,
                CredentialsSubType.ORIGINAL_FRONT
              );
            }

            if (photo) {
              this.mapVerificationImage(photo?.image, CredentialsSubType.PHOTO);
            }

            if (idBack) {
              this.mapVerificationImage(
                idBack?.image,
                CredentialsSubType?.BACK
              );
            }
          }
        }
      });
  }

  getMRZChecksum() {
    if (this.jumioUser?.mrz_checksum !== undefined) {
      if (this.jumioUser?.mrz_checksum?.check_digits) {
        this.MRZDocNumber =
          this.jumioUser?.mrz_checksum.check_digits[0]?.field_value;
        this.MRZDOB = this.jumioUser?.mrz_checksum.check_digits[1]?.valid;
        this.MRZDateExpiry =
          this.jumioUser?.mrz_checksum.check_digits[2]?.valid;
      }
    }
  }

  getVerificationScores() {
    this.faceMatchLevel = Math.round(
      (this.jumioUser?.biometric?.match_level / 5) * 100
    );

    this.screenDetectionScore = Math.round(
      100 - this.jumioUser?.id_screen_detection?.score
    );

    this.IDPhotoTamperingDetectionScore = Math.round(
      100 - this.jumioUser?.id_photo_tampering_detection?.score
    );

    this.IDPrintDetectionScore = Math.round(
      100 - this.jumioUser?.id_print_detection?.score
    );

    this.biometric =
      this.faceMatchLevel < 50 || this.faceMatchLevel === 50 ? false : true;
    this.screenCapture =
      this.screenDetectionScore < 50 || this.screenDetectionScore === 50
        ? false
        : true;
    this.genuineID =
      this.IDPhotoTamperingDetectionScore < 50 ||
      this.IDPhotoTamperingDetectionScore === 50
        ? false
        : true;
    this.noPrinted =
      this.IDPrintDetectionScore < 50 || this.IDPrintDetectionScore === 50
        ? false
        : true;
  }

  private mapVerificationImage(url: string, type: string) {
    if (type === CredentialsSubType.FRONT) {
      this.album.front = url;
      this.album.thumb = url;
      this.album.src = url;

      this.lgImage.img = this.album.front;
      this.lgImage.thumb = this.album.front;
      this.lgImage.class = 'lg-verification-img-cover';
    } else if (type === CredentialsSubType.BACK) {
      this.album.back = url;
      this.album.thumb = url;
      this.album.src = url;
    } else if (type === CredentialsSubType.FACE) {
      this.album.face = url;
      this.album.thumb = url;
      this.album.src = url;
    } else if (type === CredentialsSubType.ORIGINAL_FRONT) {
      this.album.original_front = url;
      this.album.thumb = url;
      this.album.src = url;
    } else if (type === CredentialsSubType.PHOTO) {
      this.album.photo = url;
      this.album.thumb = url;
      this.album.src = url;
    }
  }

  open(_album: string): void {
    this.dialog.open(ZoomImagesComponent, {
      data: {
        album: _album,
      },
      width: '600px',
    });
  }

  // private loadJumioV2Image(url: string, type: string) {
  //   this.dataService
  //     .getImageV2(JUMIO_USER_DETAILS_IMAGE_V2.replace('{encodedUrl}', url))
  //     .subscribe((response) => {
  //       const objectURL = URL.createObjectURL(response.body);
  //       const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       if (type === 'front') {
  //         this.verificationImages.front = img;
  //         this.lgImage.img = this.verificationImages.front;
  //         this.lgImage.class = 'lg-verification-img-cover';
  //       } else if (type === 'back') {
  //         this.verificationImages.back = img;
  //       } else if (type === 'face') {
  //         this.verificationImages.face = img;
  //       } else if (type === 'liveness') {
  //         this.verificationImages.liveness = img;
  //       } else if (type === 'one') {
  //         this.verificationImages.one = img;
  //       } else if (type === 'two') {
  //         this.verificationImages.two = img;
  //       } else if (type === 'three') {
  //         this.verificationImages.three = img;
  //       } else if (type === 'four') {
  //         this.verificationImages.four = img;
  //       }
  //     });
  // }

  // private mapJumioV2Usabilities() {
  //   const usabilities = this.jumio?.usabilities;
  //   if (!isEmpty(usabilities)) {
  //     const result = lodashMap(usabilities, (item) => {
  //       return {
  //         ...item.credentials[0],
  //         ...item.decision,
  //       };
  //     });
  //     return result;
  //   }
  //   return [];
  // }

  // private loadJumioDetails(id: any) {
  //   this.dataService
  //     .fetchData({
  //       apiUrl: JUMIO_USER_DETAILS.replace('{id}', id),
  //       method: 'GET',
  //       contentType: 'application/json',
  //       params: null,
  //       body: null,
  //     })
  //     .pipe(
  //       map((response) => {
  //         if (response.status === 'success') {
  //           return response && response.data ? response.data : null;
  //         } else if (response.status === 'failed') {
  //           return response;
  //         }
  //       })
  //     )
  //     .subscribe((response: any) => {
  //       const resps = response;

  //       if (resps && resps?.response) {
  //         const newData: any = JSON.parse(resps.response);
  //         if (newData.livenessImages) {
  //           newData.livenessImages = JSON.parse(newData?.livenessImages);
  //         }
  //         if (newData.idSubtype) {
  //           newData.idSubtype = newData?.idSubtype.replaceAll('_', ' ');
  //         }
  //         if (newData.verificationStatus === 'APPROVED_VERIFIED') {
  //           newData.identityVerification = JSON.parse(
  //             newData?.identityVerification
  //           );
  //           if (!newData.identityVerification.validity) {
  //             newData.identityVerification.reason =
  //               newData?.identityVerification.reason.replaceAll('_', ' ');
  //           }
  //         }
  //         this.jumioUser = newData;
  //         this.jumio = response;

  //         if (this.jumioUser) {
  //           if (this.jumioUser.idScanImage) {
  //             this.loadImage(this.jumioUser.idScanImage, 'front');
  //           }
  //           if (this.jumioUser.idScanImageBackside) {
  //             this.loadImage(this.jumioUser.idScanImageBackside, 'back');
  //           }
  //           if (this.jumioUser.idScanImageFace) {
  //             this.loadImage(this.jumioUser.idScanImageFace, 'face');
  //           }
  //           if (this.jumioUser.livenessImages) {
  //             this.loadImage(this.jumioUser.livenessImages[0], 'liveness');
  //           }
  //         }
  //       } else if (response.message === 'invalid.user.jumio.verfication') {
  //         this.jumio = {};
  //         this.verificationImages = { front: null, back: null, face: null };
  //         this.lgImage = { img: null, class: null };
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       }
  //     });
  // }

  // private loadJumioDetailsV2(userCode: string) {
  //   this.dataService
  //     .fetchData({
  //       apiUrl: VERIFICATION_IMAGES.replace('{userCode}', userCode),
  //       method: 'GET',
  //       contentType: 'application/json',
  //       params: null,
  //       body: null,
  //     })
  //     .pipe(
  //       map((response) => (response && response.data ? response.data : null))
  //     )
  //     .subscribe((response: any) => {
  //       console.log('response: ', response);

  //       if (response) {
  //         this.jumio = response;

  //         if (response.data && !isUndefined(response.data[0])) {
  //           this.jumioUser = response.data[0];

  //           if (this.jumioUser && this.using_jumio_v2) {
  //             const result = this.jumio?.decision?.type;
  //             if (result) {
  //               this.jumio = {
  //                 ...this.jumio,
  //                 jumio_verification_status:
  //                   result === 'PASSED' ? 'Pass' : result,
  //               };
  //             }
  //           }
  //         }

  //         if (!isEmpty(response?.images)) {
  //           const images = response?.images;
  //           const liveness = images?.liveness;

  //           if (images?.id_front) {
  //             this.loadJumioV2Image(images?.id_front, 'front');
  //           }

  //           if (images?.id_back) {
  //             this.loadJumioV2Image(images?.id_back, 'back');
  //           }
  //           if (images?.face) {
  //             this.loadJumioV2Image(images?.face, 'face');
  //           }

  //           if (liveness[0]) {
  //             this.loadJumioV2Image(liveness[0], 'liveness');
  //           }

  //           if (liveness[1]) {
  //             this.loadJumioV2Image(liveness[1], 'one');
  //           }
  //           if (liveness[2]) {
  //             this.loadJumioV2Image(liveness[2], 'one');
  //           }
  //           if (liveness[3]) {
  //             this.loadJumioV2Image(liveness[3], 'two');
  //           }
  //           if (liveness[4]) {
  //             this.loadJumioV2Image(liveness[4], 'three');
  //           }
  //         }
  //       } else if (response.message === 'invalid.user.jumio.verfication') {
  //         this.jumio = {};
  //         this.verificationImages = { front: null, back: null, face: null };
  //         this.lgImage = { img: null, class: null };
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       } else {
  //         this.jumio = {};
  //         this.verificationImages = { front: null, back: null, face: null };
  //         this.lgImage = { img: null, class: null };
  //         this.toastrService.error(
  //           'No data from JUMIO against this user.',
  //           'Failed'
  //         );
  //       }
  //     });
  // }

  // private loadJumioV2Image(url: string, type: string) {
  //   this.dataService
  //     .getImageV2(JUMIO_USER_DETAILS_IMAGE_V2.replace('{encodedUrl}', url))
  //     .subscribe((response) => {
  //       const objectURL = URL.createObjectURL(response.body);
  //       const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //       if (type === 'front') {
  //         this.verificationImages.front = img;
  //         this.lgImage.img = this.verificationImages.front;
  //         this.lgImage.class = 'lg-verification-img-cover';
  //       } else if (type === 'back') {
  //         this.verificationImages.back = img;
  //       } else if (type === 'face') {
  //         this.verificationImages.face = img;
  //       } else if (type === 'liveness') {
  //         this.verificationImages.liveness = img;
  //       } else if (type === 'one') {
  //         this.verificationImages.one = img;
  //       } else if (type === 'two') {
  //         this.verificationImages.two = img;
  //       } else if (type === 'three') {
  //         this.verificationImages.three = img;
  //       } else if (type === 'four') {
  //         this.verificationImages.four = img;
  //       }
  //     });
  // }

  // private loadImage(url: string, type: string) {
  //   this.dataService.getImage(JUMIO_USER_IMAGE, url).subscribe((response) => {
  //     const objectURL = URL.createObjectURL(response.body);
  //     const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     if (type === 'front') {
  //       this.verificationImages.front = img;
  //       this.lgImage.img = this.verificationImages.front;
  //       this.lgImage.class = 'lg-verification-img-cover';
  //     } else if (type === 'back') {
  //       this.verificationImages.back = img;
  //     } else if (type === 'face') {
  //       this.verificationImages.face = img;
  //     } else if (type === 'liveness') {
  //       this.verificationImages.liveness = img;
  //     }
  //   });
  // }
}

// this.screenDetectionScore = Math.round(
//   (this.jumioUser?.id_screen_detection?.score / 100) * 100
// );

// this.IDPhotoTamperingDetectionScore = Math.round(
//   (this.jumioUser?.id_photo_tampering_detection?.score / 100) * 100
// );

// this.IDPrintDetectionScore = Math.round(
//   (this.jumioUser?.id_print_detection?.score / 100) * 100
// );
