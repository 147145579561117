import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavService, Menu } from '../../services/nav.service';
import { PermissionService, UserService } from '../../services';
import { FormBaseComponent } from '../base/form-base.component';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent
  extends FormBaseComponent
  implements OnDestroy, OnInit
{
  public menuItems: Menu[] = [];
  public url: any;
  public user: any;
  private userSubscription$?: Subscription;

  constructor(
    injector: Injector,
    // private router: Router,
    public navServices: NavService,
    public userService: UserService,
    public permissionService: PermissionService
  ) {
    super(injector);
    if (!this.userService.isLoggedIn()) {
      this.router.navigateByUrl(this.routeList.LOGIN);
    } else {
      this.userSubscription$ = this.userService.userSubject.subscribe(
        (user) => {
          this.user = user || null;

          if (user) {
            Sentry.setUser({
              email: user?.email!,
              first_name: user?.first_name,
              last_name: user?.last_name,
              position: user?.position,
              brand_name: user?.brand_name,
              phone_number: user?.phone_number,
              country_code: user?.country_code,
            });
          }
        }
      );
    }
  }

  ngOnInit() {
    this.userService.getUserPrivileges().subscribe(() => {
      this.initSidebarComponents();
    });
  }

  ngOnDestroy() {
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }

  // Active Nave state
  setNavActive(url: string) {
    // this.menuItems = this.menuItems.map((menuItem) => {
    //   if (menuItem.path !== url) {
    //     menuItem.active = false;
    //   }
    //   if (menuItem.children && menuItem.children.includes(item)) {
    //     menuItem.active = true;
    //   }
    //   if (menuItem.children) {
    //     menuItem.children.filter((submenuItems) => {
    //       if (submenuItems.children && submenuItems.children.includes(item)) {
    //         menuItem.active = true;
    //         submenuItems.active = true;
    //       }
    //     });
    //   }
    // });
  }

  // Click Toggle menu
  toggletNavActive(item: Menu) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }

        if (!a.children) {
          return false;
        }

        a.children?.forEach((b) => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
      });
    }

    if (isEmpty(item.children) && item.type === 'sub') {
      if (item.path) {
        return this.router.navigate([item.path]);
      }
    }

    item.active = !item.active;
  }

  // Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }

  canAccessModule(
    moduleName: string,
    permission: string = this.constantList.PERMISSION_READ
  ): boolean {
    return this.permissionService.canAccessModule(moduleName, permission);
  }

  private initSidebarComponents() {
    this.navServices.items.subscribe((menuItems: Menu[]) => {
      if (this.permissionService.isSuperAdmin()) {
        this.menuItems = menuItems.filter((item) => item.isAdmin);
      } else {
        this.menuItems = menuItems.filter((item: any) =>
          this.permissionService.canAccessModule(item.name)
        );

        this.menuItems = this.menuItems.map((item) => {
          return {
            ...item,
            children: item?.children?.filter((child: any) =>
              this.permissionService.canAccessModule(child.name)
            ),
          };
        });
      }

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // this.menuItems = menuItems;
          this.setNavActive(event.url);
        }
      });
    });
  }
}
