import { Component, Input, OnInit } from '@angular/core';
import { findLastIndex } from 'lodash';

@Component({
  selector: 'app-cashout-transaction-modal',
  templateUrl: './cashout-transaction-modal.component.html',
  styleUrls: ['./cashout-transaction-modal.component.scss'],
})
export class CashoutTransactionModalComponent implements OnInit {
  @Input() modal: any;
  @Input() transaction: any;
  transactionStatus?: string;
  transactionDate?: string;
  cashoutLocation?: string;

  constructor() {}

  ngOnInit(): void {
    let updated_at: string = '';
    const transaction_status = this.transaction?.transaction_status;
    const status_history: any[] = this.transaction?.status_history;

    if (status_history) {
      const status_history_index = findLastIndex(status_history, (item) => {
        return item.status.indexOf(transaction_status) !== -1;
      });
      updated_at = status_history[status_history_index]?.updated_at;
    }
    this.transactionDate = updated_at;

    if (this.transaction?.transaction_status === 'Completed') {
      this.cashoutLocation = this.transaction?.final_branch
        ? this.transaction?.final_branch.name
        : this.transaction?.branch_dto.name;
    } else {
      this.cashoutLocation = 'N/A';
    }

    console.log('transaction: ', this.transaction);
    console.log(
      'this.transaction?.transaction_status: ',
      this.transaction?.transaction_status
    );

    switch (this.transaction?.transaction_status) {
      case 'Pending':
        this.transactionStatus = 'Issuance';
        break;
      case 'Failed':
        this.transactionStatus = 'Failed';
        break;
      case 'Completed':
        this.transactionStatus = 'Completed';
        break;
      case 'Cancelled':
        this.transactionStatus = 'Cancelled';
        break;
      case 'On Hold':
        this.transactionStatus = 'Suspended';
        break;
      case 'Expired':
        this.transactionStatus = 'Expired';
        break;
      default:
        break;
    }
  }
}
