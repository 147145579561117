<div class="p-3 suspend-transaction-modal">
  <div class="modal-header">
    <h5 class="modal-title text-center f-w-600" id="exampleModalLabel">
      <span *ngIf="!isSuspend">Suspend</span>
      <span *ngIf="isSuspend">Unsuspend</span> Transaction
    </h5>
  </div>
  <div class="modal-body">
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBasic" value="transaction" [(ngModel)]="suspend" />
      <label class="form-check-label">
        Only <span *ngIf="!isSuspend">suspend</span>
        <span *ngIf="isSuspend">unsuspend</span> this transaction
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBasic" value="user" [(ngModel)]="suspend" disabled />
      <label class="form-check-label disabled">
        <span *ngIf="!isSuspend">Suspend</span>
        <span *ngIf="isSuspend">Unsuspend</span> all transactions from
        <span *ngIf="transaction?.registered_user_name">{{
          transaction?.registered_user_name
          }}</span>
        <span *ngIf="transaction?.un_registered_user_name">{{
          transaction?.un_registered_user_name
          }}</span>
      </label>
    </div>
    <div class="form-check disabled">
      <input class="form-check-input" type="radio" name="radioBasic" value="remittance" [(ngModel)]="suspend"
        disabled />
      <label class="form-check-label">
        <span *ngIf="!isSuspend">Suspend</span>
        <span *ngIf="isSuspend">Unsuspend</span> all transactions from
        {{ transaction?.remittance_platform_name }}
      </label>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-around mt-3">
    <button type="button" class="btn btn-danger w-50 mr-3" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary w-50 ml-3" (click)="onConfirmPress()">
      <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
      <span *ngIf="!isLoading">Confirm</span>
    </button>
  </div>
</div>
