export const DASHBOARD_TABS = [
  {
    label: 'User Analytics',
    route: '/dashboard/users-dashboard',
    key: 'user_analytics',
  },
  {
    label: 'Cashout Performance',
    route: '/dashboard/cashout-performance',
    key: 'cashout_performance',
  },
  {
    label: 'Senders Analytics',
    route: '/dashboard/senders-analytics',
    key: 'senders_analytics',
  },
  {
    label: 'Transactions',
    route: '/dashboard/transaction-comparison',
    key: 'dashboard_transactions',
  },
];

export const PARTNERS_DASHBOARD_TABS = [
  {
    label: 'Transactions',
    route: '/dashboard/transfers',
    key: 'transactions',
  },
  {
    label: 'Beneficiaries',
    route: '/dashboard/beneficiaries',
    key: 'beneficiaries',
  },
];
