import { Location } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { IUser } from '../../interfaces/User';
import { FormBaseComponent } from '../base/form-base.component';
import { Features } from '../../interfaces/Features';
import _ from 'lodash';
import { Store } from '@ngrx/store';
import {
  selectAppUser,
  selectAppUserFeatures,
} from '../../stores/slices/AppUsers';

@Component({
  selector: 'purpl-dashboard-title',
  templateUrl: './app-dashboard-title.component.html',
  styleUrls: ['./app-dashboard-title.component.scss'],
})
export class AppDashboardTitleComponent
  extends FormBaseComponent
  implements OnInit
{
  @Input() position?: string;
  @Input() title?: string;
  @Input() hasBackButton?: boolean;
  @Input() details?: Partial<IUser>;

  // @Input() details: {
  //   id: string;
  //   first_name: string;
  //   last_name: string;
  //   country_code: string;
  //   phone_number: string;
  //   verification_status: string;
  //   email: string;
  //   compliance: any[];
  //   features: any[];
  //   image: string;
  //   account_non_locked: boolean;
  // } = {
  //   id: '',
  //   first_name: '',
  //   last_name: '',
  //   country_code: '',
  //   phone_number: '',
  //   verification_status: '',
  //   email: '',
  //   compliance: [],
  //   features: [],
  //   image: '',
  //   account_non_locked: false,
  // };

  features: Features[] = [];
  cashOutFeature: Features | null = null;

  constructor(
    private _location: Location,
    injector: Injector,
    private store: Store
  ) {
    super(injector);

    this.store.select(selectAppUser).subscribe((response) => {
      if (response?.compliance) {
        this.details = response;

        this.generateFeatures(this.details?.features!);
      }
    });

    this.store.select(selectAppUserFeatures).subscribe((response) => {
      if (response) {
        this.features = response;
        this.generateFeatures(this.features);
      }
    });
  }

  ngOnInit(): void {
    this.title = this.title === undefined ? 'Partners Transaction' : this.title;
  }

  backArrowClicked() {
    this._location.back();
  }

  private generateFeatures = (_features: Features[]) => {
    this.cashOutFeature = _.find(this.details?.features, {
      feature: 'cash-out',
    })!;
  };
}

// private generateFeatures = (_features: Features[]) => {

// this.store.select(selectAppUser).subscribe((response) => {
//   if (response?.compliance) {
//     this.details = response;
//     this.generateFeatures(this.details?.features!);
//   }
// });

// this.store.select(selectAppUserFeatures).subscribe((response) => {
//   if (response) {
//     this.features = response;
//     this.generateFeatures(this.features);
//   }
// });
