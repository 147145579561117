import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
} from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { DataService, UserService } from '../../services';
import { User } from '../../interfaces/User';
import { AppUsersService } from '../../services/app-users.service';
import { sumBy } from 'lodash';
import { DEFAULT_CURRENCY_CODE } from '../../constants/constant-list';
import { Store } from '@ngrx/store';
import { selectAppUser } from '../../stores/slices/AppUsers';

@Component({
  selector: 'app-breadcrumb',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: any;
  public title?: string;
  public user: User | null | undefined;

  @Input() hasBackButton?: boolean;
  @Input() headerTitle?: string;
  @Input() details?: any;
  @Input() partner?: boolean;

  TOTAL_BALANCE: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    public dataService: DataService,
    private appUserService: AppUsersService,
    private store: Store
  ) {
    this.user = this.userService.user;

    this.store.select(selectAppUser).subscribe((response) => {
      if (response) {
        this.details = response;
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route: any) => {
        const title = route.snapshot.data.title;
        const parent = route.parent.snapshot.data.breadcrumb;
        const child = route.snapshot.data.breadcrumb;
        this.breadcrumbs = {};
        this.title = title === 'User Profile' ? '' : title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
  }

  ngOnInit() {
    if (!!this.details?.compliance?.app_user_uuid) {
      this.loadBalance(this.details?.compliance?.app_user_uuid);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Changes detected:', changes);
  }

  loadBalance(user_code: string) {
    this.appUserService.getWalletBalance(user_code).subscribe({
      next: (response) => {
        const userWalletBalance = sumBy(response, (item: any) => {
          if (
            item.currency === DEFAULT_CURRENCY_CODE &&
            item.type === 'digital_wallet'
          ) {
            return item.total_balance;
          }
          return 0;
        });

        const userRemittanceBalance = sumBy(response, (item: any) => {
          if (
            item.currency === DEFAULT_CURRENCY_CODE &&
            item.type === 'received_amount_wallet'
          ) {
            return item.total_balance;
          }
          return 0;
        });

        const userAvailableBalance = sumBy(response, (item: any) => {
          if (
            item.currency === DEFAULT_CURRENCY_CODE &&
            item.type === 'digital_wallet'
          ) {
            return item.available_balance;
          }
          return 0;
        });

        const userOnHoldBalance = sumBy(response, (item: any) => {
          if (
            item.currency === DEFAULT_CURRENCY_CODE &&
            item.type === 'digital_wallet'
          ) {
            return item.on_hold_balance;
          }
          return 0;
        });

        const total_balance = sumBy(response, (item: any) => {
          if (item.currency === DEFAULT_CURRENCY_CODE) {
            return Math.round(item.total_balance);
          }
          return 0;
        });

        this.TOTAL_BALANCE = total_balance;

        // const walletBalances =
        //   Number(userWalletBalance) +
        //   Number(userRemittanceBalance) +
        //   Number(userAvailableBalance) +
        //   Number(userOnHoldBalance);

        // this.TOTAL_BALANCE = walletBalances;
        // this.TOTAL_BALANCE = total_balance;
      },
    });
  }
}
