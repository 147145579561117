<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper">
    <a class="text-center" [routerLink]="'/dashboard'">
      <img
        class="brand-logo blur-up lazyloaded"
        src="assets/images/dashboard/purpl-logo.png"
        alt=""
      />
    </a>
  </div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
    <div>
      <img
        class="img-80 rounded-circle sidebar-user-img"
        [src]="user?.image ? user?.image : 'assets/images/dashboard/man.png'"
        alt="#"
      />
    </div>
    <h6 class="mt-3 f-14">{{ user?.first_name || "" }}</h6>
    <p class="sidebar-role text-capitalize">
      {{ user?.position?.replace("_", " ") }}
    </p>
  </div>
  <ul class="sidebar-menu">
    <ng-container *ngFor="let menuItem of menuItems">
      <li [ngClass]="{ active: menuItem?.active }">
        <!-- Sub -->
        <a
          href="javascript:void(0)"
          class="sidebar-header"
          *ngIf="menuItem.type === 'sub'"
          (click)="toggletNavActive(menuItem)"
        >
          <img
            class="mr-3"
            [src]="'/assets/fonts/feather/' + menuItem.icon + '.svg'"
            [alt]="menuItem.icon"
          />
          <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
          <span
            >{{ menuItem.title }}
            <span
              class="badge badge-{{ menuItem.badgeType }} ml-3"
              *ngIf="menuItem.badgeType"
            >
              {{ menuItem.badgeValue }}
            </span>
          </span>
          <i
            class="fa fa-angle-right pull-right"
            *ngIf="menuItem?.children"
          ></i>
        </a>
        <!-- Link -->
        <a
          [routerLink]="!menuItem.type ? null : [menuItem.path]"
          routerLinkActive="active"
          class="sidebar-header"
          *ngIf="menuItem.type === 'link'"
          #rla="routerLinkActive"
        >
          <img
            class="mr-3"
            [src]="
              rla.isActive
                ? '/assets/fonts/feather/' + menuItem.icon + '-active.svg'
                : '/assets/fonts/feather/' + menuItem.icon + '.svg'
            "
            [alt]="menuItem.icon"
          />
          <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
          <span>
            {{ menuItem.title }}
            <span
              class="badge badge-{{ menuItem.badgeType }} ml-3"
              *ngIf="menuItem.badgeType"
            >
              {{ menuItem.badgeValue }}
            </span>
          </span>
          <i
            class="fa fa-angle-right pull-right"
            *ngIf="menuItem?.children"
          ></i>
        </a>
        <!-- External Link -->
        <a
          href="{{ !menuItem.type ? null : menuItem.path }}"
          class="sidebar-header"
          *ngIf="menuItem.type === 'extLink'"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span>
            {{ menuItem.title }}
            <span
              class="badge badge-{{ menuItem.badgeType }} ml-3"
              *ngIf="menuItem.badgeType"
            >
              {{ menuItem.badgeValue }}
            </span>
          </span>
          <i
            class="fa fa-angle-right pull-right"
            *ngIf="menuItem?.children"
          ></i>
        </a>
        <!-- External Tab Link -->
        <a
          href="{{ !menuItem.type ? null : menuItem.path }}"
          target="_blank"
          class="sidebar-header"
          *ngIf="menuItem.type === 'extTabLink'"
        >
          <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
          <span
            >{{ menuItem.title }}
            <span
              class="badge badge-{{ menuItem.badgeType }} ml-3"
              *ngIf="menuItem.badgeType"
            >
              {{ menuItem.badgeValue }}
            </span>
          </span>
          <i
            class="fa fa-angle-right pull-right"
            *ngIf="menuItem?.children"
          ></i>
        </a>
        <!-- 2nd Level Menu -->
        <ul
          class="sidebar-submenu"
          [ngClass]="{
            'menu-open': menuItem?.active,
            'menu-close': !menuItem?.active
          }"
          *ngIf="menuItem?.children"
        >
          <ng-container
            *ngIf="menuItem?.children && menuItem?.children?.length > 0"
          >
            <li
              *ngFor="let childrenItem of menuItem?.children"
              [ngClass]="{ active: childrenItem?.active }"
            >
              <!-- Sub -->
              <a
                href="javascript:void(0)"
                *ngIf="childrenItem?.type === 'sub'"
                (click)="toggletNavActive(childrenItem)"
              >
                <i class="fa fa-circle"></i>
                <span>
                  {{ childrenItem?.title }}
                  <span
                    class="badge badge-{{ childrenItem?.badgeType }} pull-right"
                    *ngIf="childrenItem?.badgeType"
                  >
                    {{ childrenItem?.badgeValue }}
                  </span>
                </span>
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem?.children"
                ></i>
              </a>
              <!-- Link -->
              <a
                [routerLink]="!childrenItem?.type ? null : [childrenItem?.path]"
                *ngIf="childrenItem?.type === 'link'"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fa fa-circle"></i>
                <span
                  >{{ childrenItem?.title }}
                  <span
                    class="badge badge-{{ childrenItem?.badgeType }} pull-right"
                    *ngIf="childrenItem?.badgeType"
                  >
                    {{ childrenItem?.badgeValue }}
                  </span></span
                >
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem?.children"
                >
                </i>
              </a>
              <!-- External Link -->
              <a
                href="{{ !childrenItem?.type ? null : childrenItem?.path }}"
                *ngIf="childrenItem?.type === 'extLink'"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fa fa-circle"></i
                ><span
                  >{{ childrenItem?.title }}
                  <span
                    class="badge badge-{{ childrenItem?.badgeType }} pull-right"
                    *ngIf="childrenItem?.badgeType"
                    >{{ childrenItem?.badgeValue }}
                  </span>
                </span>
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem?.children"
                ></i>
              </a>
              <!-- External Tab Link -->
              <a
                href="{{ !childrenItem?.type ? null : childrenItem?.path }}"
                target="_blank"
                *ngIf="childrenItem?.type === 'extTabLink'"
              >
                <i class="fa fa-circle"></i
                ><span
                  >{{ childrenItem?.title }}
                  <span
                    class="badge badge-{{ childrenItem?.badgeType }} pull-right"
                    *ngIf="childrenItem?.badgeType"
                    >{{ childrenItem?.badgeValue }}</span
                  ></span
                >
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem?.children"
                ></i>
              </a>
              <!-- 3rd Level Menu -->
              <ul class="sidebar-submenu" *ngIf="childrenItem?.children">
                <li *ngFor="let childrenSubItem of childrenItem?.children">
                  <!-- Link -->
                  <a
                    [routerLink]="
                      !childrenSubItem.type ? null : [childrenSubItem.path]
                    "
                    *ngIf="childrenSubItem.type === 'link'"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <i class="fa fa-circle"></i
                    ><span>
                      {{ childrenSubItem.title }}
                      <span
                        class="badge badge-{{
                          childrenSubItem.badgeType
                        }} pull-right"
                        *ngIf="childrenSubItem.badgeType"
                        >{{ childrenSubItem.badgeValue }}</span
                      >
                    </span>
                  </a>
                  <!-- External Link -->
                  <a
                    href="{{
                      !childrenSubItem.type ? null : childrenSubItem.path
                    }}"
                    *ngIf="childrenSubItem.type === 'extLink'"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <i class="fa fa-circle"></i
                    ><span
                      >{{ childrenSubItem.title }}
                      <span
                        class="badge badge-{{
                          childrenSubItem.badgeType
                        }} pull-right"
                        *ngIf="childrenSubItem.badgeType"
                        >{{ childrenSubItem.badgeValue }}</span
                      ></span
                    >
                  </a>
                  <!-- External Tab Link -->
                  <a
                    href="{{
                      !childrenSubItem.type ? null : childrenSubItem.path
                    }}"
                    target="_blank"
                    *ngIf="childrenSubItem.type === 'extTabLink'"
                  >
                    <i class="fa fa-circle"></i
                    ><span
                      >{{ childrenSubItem.title }}
                      <span
                        class="badge badge-{{
                          childrenSubItem.badgeType
                        }} pull-right"
                        *ngIf="childrenSubItem.badgeType"
                        >{{ childrenSubItem.badgeValue }}</span
                      ></span
                    >
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
