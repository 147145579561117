import * as React from 'react';
import InfiniteCalendar, { Calendar, ReactInfiniteCalendarProps, withRange } from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once


export interface PurplCalendarProps extends ReactInfiniteCalendarProps {
  isRange?: boolean;
}

const CalendarComponent = withRange(Calendar);


export const PurplCalendar: React.FunctionComponent<PurplCalendarProps> = (props: PurplCalendarProps) => {
  return (
    <InfiniteCalendar
      width={380}
      height={330}
      maxDate={new Date()}
      displayOptions={{
        showHeader: false,
      }}
      autoFocus={true}
      Component={CalendarComponent}
      theme={{
        todayColor: '#66023c',
        selectionColor: '#66023c',
        accentColor: '#973a66',
        textColor: {
          default: '#44546A',
          active: '#FFF'
        },
        weekdayColor: '#ECEEF7',
        headerColor: '#66023c',
      }}
      {...props}
    />
  )
}
