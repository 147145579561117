import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-zoom-images',
  templateUrl: './zoom-images.component.html',
  styleUrls: ['./zoom-images.component.scss'],
})
export class ZoomImagesComponent implements OnInit {
  img?: string;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { album: string }
  ) {}

  ngOnInit(): void {
    this.img = this.data.album;
  }
}
