import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'purpl-skeleton-item',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './skeleton-item.component.html',
  styleUrls: ['./skeleton-item.component.scss'],
})
export class SkeletonItemComponent implements OnInit {
  @Input() width: any;
  @Input() height: any;
  @Input() radius: any;

  styles: any = {};

  constructor() {}

  ngOnInit() {
    this.styles = {
      width: this.width ? this.width : '100%',
      height: this.height ? this.height : '16px',
    };

    if (typeof this.radius !== 'undefined' && this.radius !== '') {
      this.styles.borderRadius = this.radius;
    }
  }
}
