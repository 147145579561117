<ng-container>
  <ng-container>
    <select class="form-control cursor">
      <option selected value="all">All</option>
      <option
        *ngFor="let option of options || []"
        [value]="option.value | lowercase"
      >
        {{ option.name }}
      </option>
    </select>
  </ng-container>
</ng-container>

<!-- (change)="onSearchInputChanged($event.target.value)" -->
