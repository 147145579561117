import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService } from '../../services/nav.service';
import { SharedDataService, UserService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  public right_sidebar = false;
  public open = false;
  public openNav = false;
  public isOpenMobile?: boolean;
  public user: any;

  private userSubscription$: Subscription;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, public userService: UserService, public sharedDataService: SharedDataService) {
    this.userSubscription$ = this.userService.userSubject.subscribe(user => {
      this.user = user || null;
    });
  }

  ngOnDestroy() {
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }
}
